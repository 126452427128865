import TreeView, { flattenTree } from "react-accessible-treeview";
import { IoMdArrowDropright } from "react-icons/io";
import { useFactors } from "../../../hooks/query/useFactors";
import "./FactorTree.css";
import { FactorDetailsType, FactorStrategy } from "../../../api/schema";
import { Form, FormControl } from "react-bootstrap";
import { useEffect, useMemo, useRef, useState } from "react";
import { useUniverse } from "../../../hooks/query/useUniverse";

const FactorTree = ({
    onChange,
    selectedRow,
    search,
    filterValue,
    setSearch,
}: {
    onChange: (factor: FactorStrategy) => void;
    selectedRow?: FactorStrategy;
    search: string;
    filterValue: string;
    setSearch: (s: string) => void;
}) => {
    const { useFactorsListQuery, useFactorDetailsQuery } = useFactors();
    const { useBenchmarksQuery } = useUniverse();

    const factorsQuery = useFactorsListQuery();
    const factorsDetailsQuery = useFactorDetailsQuery();
    const benchmarksQuery = useBenchmarksQuery();
    const focusedNode = useRef<HTMLDivElement | null>(null);
    const [selectedIds, setSelectedIds] = useState<string[]>([]);
    const [expandedIds, setExpandedIds] = useState<string[]>([]);

    const buckets = useMemo(() => {
        return factorsDetailsQuery.data
            ? [
                  ...factorsDetailsQuery.data.reduce((acc, f) => {
                      acc.add(f.Bucket);
                      return acc;
                  }, new Set<string>()),
              ]
            : [];
    }, [factorsDetailsQuery.data]);

    const factorsInBucket = useMemo(() => {
        return factorsDetailsQuery.data?.reduce((acc, f) => {
            if (!acc.has(f.Bucket)) {
                acc.set(f.Bucket, []);
            }
            acc.get(f.Bucket)?.push(f.Feature);
            return acc;
        }, new Map<string, string[]>());
    }, [factorsDetailsQuery.data]);

    const bucketMap = useMemo(() => {
        const factorDetailsMap = factorsDetailsQuery.data?.reduce((acc, f) => {
            acc.set(f.Feature, f);
            return acc;
        }, new Map<string, FactorDetailsType>());

        return factorsQuery.data && factorDetailsMap
            ? factorsQuery.data.reduce((acc, factor) => {
                  factor.factors.forEach((f) => {
                      const { factors, ...rest } = factor;
                      const extendedFactor = {
                          ...f,
                          ...rest,
                      };
                      acc.set(`${factor.PK}  ${f.Factor}`, extendedFactor);

                      return acc;
                  });

                  return acc;
              }, new Map<string, FactorStrategy>())
            : new Map<string, FactorStrategy>();
    }, [factorsQuery.data, factorsDetailsQuery.data]);

    const factors = useMemo(() => {
        return (factorsQuery.data || []).map((factor, i) => {
            const b = buckets
                .map((bucket: string) => {
                    const a = factorsInBucket?.get(bucket) || [];
                    return {
                        id: `${factor.PK} ${bucket}`,
                        name: bucket as string,
                        children: [...a]
                            .filter((f) => {
                                const factorDetail = bucketMap.get(
                                    `${factor.PK}  ${f}`
                                );
                                return (
                                    factorDetail?.["P-Value"] &&
                                    factorDetail["T-Stat"]
                                );
                            })
                            .map((f) => {
                                const test = bucketMap.get(
                                    `${factor.PK}  ${f}`
                                );
                                return {
                                    id: `${test?.PK}  ${test?.Factor}`,
                                    name: test?.Factor || "",
                                };
                            }),
                    };
                })
                .filter((b) => b.children.length > 0);

            return {
                id: `${factor.PK}`,
                name: benchmarksQuery.data
                    ? Object.values(benchmarksQuery.data.results).find(
                          (b) => b.universe === factor.poolName
                      )?.name || factor.poolName
                    : factor.poolName,
                children: b,
            };
        });
    }, [
        factorsQuery.data,
        factorsDetailsQuery.data,
        benchmarksQuery.data,
        factorsInBucket,
        bucketMap,
    ]);

    useEffect(() => {
        if (
            selectedRow &&
            (selectedIds.length === 0 ||
                selectedIds[0] !== `${selectedRow.PK}  ${selectedRow.Factor}`)
        ) {
            setSelectedIds([`${selectedRow.PK}  ${selectedRow.Factor}`]);
            setTimeout(() => {
                focusedNode.current?.focus();
            }, 500);
        }
    }, [selectedRow, selectedIds]);

    useEffect(() => {
        if (!selectedRow) {
            return;
        }
        const parentTree = factors.find((f) => f.id === selectedRow.PK);
        if (!parentTree || parentTree.children.length === 0) {
            return;
        }

        if (
            selectedIds[0] === `${selectedRow.PK}  ${selectedRow.Factor}` &&
            expandedIds.length > 0
        ) {
            return;
        }

        const test = parentTree.children
            .reduce((acc: string[], b) => {
                const f = b.children.map((c) => c.name);
                return [...acc, ...f];
            }, [])
            .some((f) => f === selectedRow.Factor);
        if (!test) {
            setExpandedIds([]);
        }

        const factorDetail = factorsDetailsQuery.data?.find(
            (f) => f.Feature === selectedRow.Factor
        );
        if (factorDetail) {
            setExpandedIds([
                `${selectedRow.PK}`,
                `${selectedRow.PK} ${factorDetail?.Bucket}`,
            ]);
        }
    }, [selectedRow, factorsDetailsQuery.data, factors, expandedIds]);

    const data = flattenTree({
        name: "",
        children: factors,
    });
    return (
        <>
            <Form className="my-2">
                <FormControl
                    placeholder="Search for indicator"
                    value={search}
                    onChange={(e) => {
                        setSearch(e.target.value);
                        e.preventDefault();
                    }}
                />
            </Form>
            <div
                style={{
                    height: "83vh",
                    overflow: "auto",
                }}
            >
                {!factors.some((f: any) => f.children.length !== 0) ? (
                    <div>None of the factors meet the selection criteria</div>
                ) : (
                    <TreeView
                        data={data}
                        className="basic"
                        multiSelect={false}
                        selectedIds={selectedIds}
                        expandedIds={expandedIds}
                        aria-label="basic example tree"
                        nodeRenderer={({
                            element,
                            isBranch,
                            isExpanded,
                            isSelected,
                            getNodeProps,
                            level,
                            handleSelect,
                            handleExpand,
                        }) => {
                            return (
                                <div
                                    {...getNodeProps()}
                                    {...(isSelected
                                        ? { ref: focusedNode }
                                        : {})}
                                    style={{
                                        marginLeft: 20 * (level - 1),
                                        display:
                                            isBranch ||
                                            element.name
                                                .toLowerCase()
                                                .includes(
                                                    filterValue.toLowerCase()
                                                )
                                                ? "flex"
                                                : "none",
                                    }}
                                    className={
                                        isBranch ||
                                        element.name
                                            .toLowerCase()
                                            .includes(filterValue.toLowerCase())
                                            ? "visible"
                                            : "filtered"
                                    }
                                    onClick={(e) => {
                                        if (!isBranch) {
                                            const node =
                                                bucketMap.get(
                                                    element.id.toString()
                                                ) || ({} as FactorStrategy);
                                            console.log(node.PK);
                                            onChange(node);
                                            handleSelect(e);
                                        } else {
                                            handleExpand(e);
                                        }
                                    }}
                                >
                                    {isBranch ? (
                                        <>
                                            <ArrowIcon isOpen={isExpanded} />
                                            <span className="name">
                                                {element.name}
                                            </span>
                                        </>
                                    ) : (
                                        <>
                                            <span
                                                id={element.id.toString()}
                                                className={`name ${isSelected ? "active" : ""}`}
                                            >
                                                {element.name}
                                            </span>
                                        </>
                                    )}
                                </div>
                            );
                        }}
                    />
                )}
            </div>
        </>
    );
};

const ArrowIcon = ({ isOpen }: { isOpen: boolean }) => {
    const baseClass = `arrow--${isOpen ? "open" : "closed"}`;
    return <IoMdArrowDropright className={baseClass} />;
};

//   const CheckBoxIcon = ({ variant }: {
//     variant: "all" | "none" | "some";
//   }) => {
//     switch (variant) {
//       case "all":
//         return <FaCheckSquare/>;
//       case "none":
//         return <FaSquare />;
//       case "some":
//         return <FaMinusSquare />;
//       default:
//         return null;
//     }
//   };

export default FactorTree;
