import { Button, Col, Row, Stack } from "react-bootstrap";
import { useFactors } from "../../../hooks/query/useFactors";
import { useCallback, useEffect, useState } from "react";
import { FactorStrategy } from "../../../api/schema";
import FactorOverview from "./FactorOverview";
import ModelAttributes from "./ModelAttributes";
import Card from "../../common/Card";
import FactorTree from "./FactorTree";
import { useLocation, useParams } from "react-router-dom";
import { useModels } from "../../../hooks/query/useModel";
import FactorMimickingDialogTimeframe from "./dialogs/FactorMimicking/FactorMimickingDialogTimeframe";
import { useDebounce } from "../../../hooks/useDebounce";

const ModelConfiguration = () => {
    const { useModelQuery } = useModels();
    const { useFactorsListQuery } = useFactors();
    const location = useLocation();
    const f = new URLSearchParams(location.search).get("factor");
    const s = new URLSearchParams(location.search).get("strategy");
    let { modelId } = useParams();
    const [showTimeframeMimickingDialog, setTimeframeShowMimickingDialog] =
        useState(false);
    const [search, setSearch] = useState("");
    const filterText = useDebounce(search, 300);
    const modelQuery = useModelQuery(modelId || "");
    const factorsQuery = useFactorsListQuery();

    useEffect(() => {
        if (factorsQuery.data) {
            const factorName =
                f || Object.keys(modelQuery.data?.factor || {})[0];
            const strategyName = s || modelQuery.data?.strategyId;

            const stategy = factorsQuery.data?.find(
                (strategy) => strategy.PK === strategyName
            );
            const factor = stategy?.factors.find(
                (f) => f.Factor === factorName
            );
            if (stategy && factor) {
                const { factors, ...rest } = stategy;
                setSelectedRow({
                    ...factor,
                    ...rest,
                });
            }
        }
    }, [modelQuery.data, factorsQuery.data, f, s]);

    const [selectedRow, setSelectedRow] = useState<FactorStrategy>();
    const onFactorSelected = useCallback((factor: FactorStrategy) => {
        setSelectedRow(factor);
        console.log(factor.Factor);
    }, []);

    if (factorsQuery.data) {
        return (
            <>
                <Stack gap={3}>
                    <Row>
                        <Col md={3}>
                            <Card>
                                <Button
                                    className="w-100"
                                    variant="primary"
                                    onClick={() =>
                                        setTimeframeShowMimickingDialog(true)
                                    }
                                >
                                    Factor Mimicking
                                </Button>
                                <FactorTree
                                    selectedRow={selectedRow}
                                    onChange={onFactorSelected}
                                    search={search}
                                    filterValue={filterText}
                                    setSearch={setSearch}
                                />
                            </Card>
                        </Col>
                        <Col md={9}>
                            {selectedRow ? (
                                <>
                                    <ModelAttributes factor={selectedRow} />
                                    <FactorOverview factor={selectedRow} />
                                </>
                            ) : (
                                <>
                                    <Card
                                        style={{
                                            height: "100%",
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: "flex",
                                                textAlign: "center",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    margin: "auto",
                                                }}
                                            >
                                                <p>
                                                    Select some factor in the
                                                    hierarchy
                                                </p>
                                            </div>
                                        </div>
                                    </Card>
                                </>
                            )}
                        </Col>
                    </Row>
                </Stack>
                <FactorMimickingDialogTimeframe
                    show={showTimeframeMimickingDialog}
                    onDialogClose={(factorStrategy) => {
                        setTimeframeShowMimickingDialog(false);
                        if (factorStrategy) {
                            setSearch("");
                            setSelectedRow(factorStrategy);
                        }
                    }}
                />
            </>
        );
    }

    return <div>Loading...</div>;
};

export default ModelConfiguration;
