import AuthClientStore from "../api/authClientStore";
import { HttpMethod, useApi } from "./api/useApi";
import { User } from "../context/authContext";
import { useWS } from "./api/useWS";
import { useState } from "react";
import { ApiError } from "aws-amplify/api";
import useCachedAsync from "./utils/useCachedAsync";

export type ApiMethod = {
    GET: "GET";
    POST: "POST";
    PUT: "PUT";
    DELETE: "DELETE";
};

export const useAuthApi = () => {
    const { sendANPApiRequest, sendAWSApiRequest } = useApi();

    const logout = () => {
        AuthClientStore.removeAccessToken();
        AuthClientStore.removeRefreshToken();
    };

    const refreshTokens = async () => {
        console.log("refreshing tokens");
        const refreshToken = AuthClientStore.getRefreshToken();
        const response = await sendANPApiRequest<{
            access: string;
            refresh: string;
        }>(
            "POST",
            "/api/token/refresh/",
            {
                refresh: refreshToken,
            },
            false,
            {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            }
        );
        const { access, refresh } = response.data;
        AuthClientStore.setAccessToken(access);
        AuthClientStore.setRefreshToken(refresh);
    };

    const { execute } = useCachedAsync(refreshTokens, 10000);

    const sendAuthGuardedRequest = async <T>(
        method: HttpMethod,
        path: string,
        body?: any,
        init?: RequestInit
    ) => {
        try {
            return await sendAWSApiRequest<T>(
                method,
                path,
                body,
                undefined,
                init
            );
        } catch (error: any) {
            const apiError = error as ApiError;
            const data = apiError.response;

            if (data?.statusCode === 401) {
                try {
                    await execute();
                } catch (e) {
                    throw e;
                }
                return await sendAWSApiRequest<T>(
                    method,
                    path,
                    body,
                    undefined,
                    init
                );
            }

            throw error;
        }
    };

    const [userPromise, setUserPromise] = useState<Promise<User> | undefined>();

    const me = async (userIsNotAuthenticatedCallback: () => void) => {
        if (userPromise) {
            return userPromise;
        }

        try {
            let response = await sendANPApiRequest<User>(
                "GET",
                "/rest-auth/user/",
                undefined,
                true
            );

            if (response?.status === 401) {
                try {
                    await refreshTokens();
                } catch (e) {
                    userIsNotAuthenticatedCallback();
                    throw e;
                }
                response = await sendANPApiRequest(
                    "GET",
                    "/rest-auth/user/",
                    undefined,
                    true
                );
            }
            setUserPromise(Promise.resolve(response.data));
            return response.data;
        } catch (e: any) {
            throw e;
        }
    };

    useWS({
        userPromise,
        onError: async () => {
            await execute();
        },
    });

    return { logout, me, sendAuthGuardedRequest };
};
