import { Col } from "react-bootstrap";
import { FactorModelType } from "../../../api/schema";
import EmptySlot from "./EmptySlot";
import ModelCard from "./ModelCard";

const UserStrategies = ({ ownModels }: { ownModels: FactorModelType[] }) => {
    // const isAuthorized = user && user.permissions && user.permissions.includes("Factor_investing_advanced");
    const isAuthorized = true;
    const slotsCount = isAuthorized ? 5 : 1;

    const emptySlots = Array(5).fill(
        <Col sm={isAuthorized ? 4 : 12} className="my-3">
            <EmptySlot />
        </Col>
    );
    const modelSlots = ownModels.map((model, i) => (
        <ModelCard model={model} key={i} />
    ));

    const slots = [...modelSlots, ...emptySlots].slice(0, slotsCount);

    return (
        <>
            {slots.map((slot, i) => (
                <div key={i}>{slot}</div>
            ))}
        </>
    );
};

export default UserStrategies;
