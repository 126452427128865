import {
    f3FamaFrenchResponseSchema,
    F3FamaFrenchResponseType,
    f5FamaFrenchResponseSchema,
    F5FamaFrenchResponseType,
    FactorCumulativeReturnResponse,
    factorCumulativeReturnSchema,
    factorDetailsResponseSchema,
    FactorDetailsType,
    factorListResponseSchema,
    FactorListResponseType,
    factorMeanQuantileResponseSchema,
    FactorMeanQuantileResponseType,
    FactorMimickingZoomResponseType,
    FactorMimickingZoomType,
    FactorMomentumResponse,
    factorMomentumSchema,
    FactorPerformanceReducedType,
    FactorPerformanceResponseType,
    FactorRankingItem,
    factorRankingSchema,
    factorResponseSchema,
    longShortCumulativeResponseSchema,
    LongShortCumulativeResponseType,
    modelAccuracySchema,
    ModelAccuracyType,
} from "../../../api/schema";
import { useAuthApi } from "../../useAuthApi";
import { HTTP_METHODS } from "../useApi";

export const useFactorApi = () => {
    const { sendAuthGuardedRequest } = useAuthApi();

    const fetchFactorRanking = async (userId: string, portfolioId: string) => {
        const factorRanking = await sendAuthGuardedRequest<FactorRankingItem[]>(
            HTTP_METHODS.GET,
            `/backtest/${userId}/${portfolioId}/factors`
        );
        const validation = factorRankingSchema.safeParse(factorRanking.data);
        if (!validation.success) {
            throw new Error(JSON.stringify(validation.error));
        }

        return factorRanking.data;
    };

    const fetchFactorMomentum = async (
        userId: string,
        strategyId: string,
        factor: string
    ) => {
        const factorRanking =
            await sendAuthGuardedRequest<FactorMomentumResponse>(
                HTTP_METHODS.GET,
                `/factor/${userId}/${strategyId}/${factor}/momentum`
            );
        const validation = factorMomentumSchema.safeParse(factorRanking.data);
        if (!validation.success) {
            throw new Error(JSON.stringify(validation.error));
        }

        return factorRanking.data.results;
    };

    const fetchFactorCumulativeReturn = async (
        userId: string,
        strategyId: string,
        factors: string
    ) => {
        const factorRanking =
            await sendAuthGuardedRequest<FactorCumulativeReturnResponse>(
                HTTP_METHODS.GET,
                `/factor/${userId}/${strategyId}/cumulative-return?factors=${factors}`
            );
        const validation = factorCumulativeReturnSchema.safeParse(
            factorRanking.data
        );
        if (!validation.success) {
            throw new Error(JSON.stringify(validation.error));
        }

        return factorRanking.data.results;
    };

    const factorList = async () => {
        const modelsResponse =
            await sendAuthGuardedRequest<FactorListResponseType>(
                HTTP_METHODS.GET,
                `/factor`
            );
        const validation = factorListResponseSchema.safeParse(
            modelsResponse.data
        );
        if (!validation.success) {
            throw new Error(JSON.stringify(validation.error));
        }

        return modelsResponse.data.result;
    };

    const factorDetails = async () => {
        const modelsResponse = await sendAuthGuardedRequest<
            FactorDetailsType[]
        >(HTTP_METHODS.GET, `/factor/details`);
        const validation = factorDetailsResponseSchema.safeParse(
            modelsResponse.data
        );
        if (!validation.success) {
            console.log(validation.error);
            throw new Error(JSON.stringify(validation.error));
        }

        return modelsResponse.data;
    };

    const fetchF3FamaFrench = async () => {
        const response = await sendAuthGuardedRequest<F3FamaFrenchResponseType>(
            HTTP_METHODS.GET,
            `/factor/fama-french/3f`
        );
        const validation = f3FamaFrenchResponseSchema.safeParse(response.data);
        if (!validation.success) {
            console.log(validation.error);
            throw new Error(JSON.stringify(validation.error));
        }

        return response.data.results;
    };

    const fetchF5FamaFrench = async () => {
        const response = await sendAuthGuardedRequest<F5FamaFrenchResponseType>(
            HTTP_METHODS.GET,
            `/factor/fama-french/5f`
        );
        const validation = f5FamaFrenchResponseSchema.safeParse(response.data);
        if (!validation.success) {
            console.log(validation.error);
            throw new Error(JSON.stringify(validation.error));
        }

        return response.data.results;
    };

    const fetchMeanQuantiles = async (
        userId: string,
        strategyId: string,
        factor: string
    ) => {
        const meanQuantileResponse =
            await sendAuthGuardedRequest<FactorMeanQuantileResponseType>(
                HTTP_METHODS.GET,
                `/factor/${userId}/${strategyId}/${factor}/mean-quantiles`
            );
        const validation = factorMeanQuantileResponseSchema.safeParse(
            meanQuantileResponse.data
        );
        if (!validation.success) {
            throw new Error(JSON.stringify(validation.error));
        }

        return meanQuantileResponse.data.result;
    };

    const fetchModelAccuracy = async (
        userId: string,
        strategyId: string,
        factor: string
    ) => {
        const modelAccuracyResponse =
            await sendAuthGuardedRequest<ModelAccuracyType>(
                HTTP_METHODS.GET,
                `/factor/${userId}/${strategyId}/${factor}/model-accuracy`
            );
        const validation = modelAccuracySchema.safeParse(
            modelAccuracyResponse.data
        );
        if (!validation.success) {
            throw new Error(JSON.stringify(validation.error));
        }

        return modelAccuracyResponse.data;
    };

    const fetchLongShortCumulative = async (
        userId: string,
        strategyId: string,
        factor: string
    ) => {
        const longShortCumulative =
            await sendAuthGuardedRequest<LongShortCumulativeResponseType>(
                HTTP_METHODS.GET,
                `/factor/${userId}/${strategyId}/${factor}/long-short-cumulative`
            );
        const validation = longShortCumulativeResponseSchema.safeParse(
            longShortCumulative.data
        );
        if (!validation.success) {
            console.log(validation.error);
            throw new Error(JSON.stringify(validation.error));
        }

        return longShortCumulative.data.results;
    };

    const fetchTopFactors = async () => {
        const topFactorResponse =
            await sendAuthGuardedRequest<FactorPerformanceResponseType>(
                HTTP_METHODS.GET,
                `/factor/admin/top`
            );

        const validation = factorResponseSchema.safeParse(
            topFactorResponse.data
        );
        if (!validation.success) {
            console.log(validation.error);
            throw new Error(JSON.stringify(validation.error));
        }

        return Object.values(topFactorResponse.data.results).reduce(
            (acc: FactorPerformanceReducedType, item: any) => {
                const performance = acc[item.Strategy] || [];

                return {
                    ...acc,
                    [item.Strategy]: [
                        ...performance,
                        {
                            factor: item.Factor,
                            performance: item.Value,
                            date: new Date(item.Date),
                        },
                    ],
                };
            },
            {} as FactorPerformanceReducedType
        );
    };

    const fetchBottomFactors = async () => {
        const topFactorResponse =
            await sendAuthGuardedRequest<FactorPerformanceResponseType>(
                HTTP_METHODS.GET,
                `/factor/admin/bottom`
            );

        const validation = factorResponseSchema.safeParse(
            topFactorResponse.data
        );
        if (!validation.success) {
            console.log(validation.error);
            throw new Error(JSON.stringify(validation.error));
        }

        return Object.values(topFactorResponse.data.results).reduce(
            (acc: FactorPerformanceReducedType, item: any) => {
                const performance = acc[item.Strategy] || [];

                return {
                    ...acc,
                    [item.Strategy]: [
                        ...performance,
                        {
                            factor: item.Factor,
                            performance: item.Value,
                            date: new Date(item.Date),
                        },
                    ],
                };
            },
            {} as FactorPerformanceReducedType
        );
    };

    const zoomFactorMimickingData = async (data: FactorMimickingZoomType) => {
        try {
            const response = await sendAuthGuardedRequest<{
                result: FactorMimickingZoomResponseType;
            }>(HTTP_METHODS.POST, "/factor/change-timeframe", data);
            return response.data.result;
        } catch (error) {
            console.log(error);
            throw new Error("Factor mimicking data was not fetched");
        }
    };

    const fetchFactorListById = async (strategyId: string) => {
        const response = await sendAuthGuardedRequest<{
            result: FactorRankingItem[];
        }>(HTTP_METHODS.GET, `/factor/admin/${strategyId}/zoom`);
        const factorList = response.data.result;
        const validation = factorRankingSchema.safeParse(factorList);
        if (!validation.success) {
            console.log(validation.error);
            throw new Error(JSON.stringify(validation.error));
        }

        return factorList;
    };

    return {
        factorDetails,
        factorList,
        fetchBottomFactors,
        fetchF3FamaFrench,
        fetchF5FamaFrench,
        fetchFactorCumulativeReturn,
        fetchFactorListById,
        fetchFactorMomentum,
        fetchFactorRanking,
        fetchLongShortCumulative,
        fetchMeanQuantiles,
        fetchModelAccuracy,
        fetchTopFactors,
        zoomFactorMimickingData,
    };
};
