import "../../App.css";
import "../../assets/styles/index.scss";

import Overlay from "../common/Overlay";
import MessageCenter from "../common/MessageCenter";

function Root({ children }: { children: React.ReactNode }) {
    return (
        <div className="App">
            <Overlay />
            <MessageCenter />
            {children}
        </div>
    );
}

export default Root;
