import { Card } from "react-bootstrap";
import { F5FamaFrenchType } from "../../../api/schema";
import ReactECharts from "echarts-for-react";
import styles from "./ModelCard.module.scss";
import { padNumber, toFixed } from "../../../utils/utils";

const FamaFrench5F = ({ data }: { data: F5FamaFrenchType }) => {
    const options = {
        legend: {},
        xAxis: {
            type: "time",
        },
        yAxis: {
            type: "value",
            axisLabel: {
                // eslint-disable-next-line no-template-curly-in-string
                formatter: "${value}",
                margin: 2,
            },
            name: "Final Equity",
            nameLocation: "middle",
            nameGap: 55,
        },
        series: [
            "hmlCumulative",
            "smbCumulative",
            "rmwCumulative",
            "cmaCumulative",
            "mktRfCumulative",
        ].map((factor) => {
            const f = factor as
                | "hmlCumulative"
                | "smbCumulative"
                | "mktRfCumulative"
                | "rmwCumulative"
                | "cmaCumulative";
            const map = {
                hmlCumulative: "High Minus Low",
                smbCumulative: "Small Minus Big",
                mktRfCumulative: "Market Premium",
                rmwCumulative: "Robust Minus Weak",
                cmaCumulative: "Conservative Minus Aggressive",
            };
            return {
                name: map[f],
                type: "line",
                showSymbol: false,
                data: Object.keys(data).map((date: any) => {
                    return [new Date(date), data[date][f]];
                }),
            };
        }),
        tooltip: {
            trigger: "axis",
            formatter: function (param: any) {
                return `<div>Date: ${(param[0].value[0] as Date).toLocaleDateString()}</div>
                    ${param.map((p: any) => `<div style="display:flex; justify-content:space-between"><span style="margin-right:10px">${p.marker} ${p.seriesName}</span> <span>$${padNumber(toFixed(p.value[1]), 2)}</span></div>`).join("")}
                    `;
            },
        },
    };
    return (
        <div
            className={styles.card}
            style={{
                height: "100%",
            }}
        >
            <Card
                style={{
                    height: "inherit",
                    display: "flex",
                    flexDirection: "column",
                    marginBottom: "3rem",
                }}
            >
                <ReactECharts option={options} />
            </Card>
        </div>
    );
};

export default FamaFrench5F;
