import { Button, Modal } from "react-bootstrap";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";
import { FactorModelType, FactorStrategy } from "../../../../api/schema";
import { useBusy } from "../../../../hooks/useBusy";
import { useMessages } from "../../../../hooks/useMessages";
import { useModelApi } from "../../../../hooks/api/aws/useModelApi";

const ConfirmationDialog = ({
    show,
    factor,
    model,
    handleClose,
    chosenFactor,
    setError,
    setAvailableModels,
}: {
    show: boolean;
    factor: FactorStrategy;
    model?: FactorModelType;
    chosenFactor: string;
    handleClose: () => void;
    setError: (value: boolean) => void;
    setAvailableModels: (value: FactorModelType[]) => void;
}) => {
    const { recalculateModel, updateModel } = useModelApi();
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const { modelId } = useParams();
    const { setIsBusy } = useBusy();
    const { addErrorMessage, addInfoMessage } = useMessages();

    const mutation = useMutation({
        mutationFn: async () => {
            handleClose();
            setIsBusy(true);
            const updatedModel = await updateModel(
                model?.userId || "",
                modelId || "",
                {
                    userId: model?.userId || "",
                    modelId: modelId || "",
                    strategyId: factor.PK,
                    factor: {
                        [factor.Factor]: 1,
                    },
                }
            );

            await recalculateModel(
                updatedModel.userId,
                updatedModel.modelId,
                factor.PK,
                {
                    ascending: factor.Direction === -1,
                }
            );
            setIsBusy(false);
            return updatedModel;
        },
        onSuccess: (data: FactorModelType) => {
            addInfoMessage(
                "Portfolio updated",
                "Factor model of your portfolio has been successfully updated"
            );
            queryClient.invalidateQueries({ queryKey: ["models"] });
            navigate(`/${data.modelId}`);
        },
        onError: (error: any) => {
            setIsBusy(false);
            if (error.$metadata.httpStatusCode === 403) {
                setError(true);
                const availableModels = JSON.parse(
                    JSON.parse(error.response.body)?.models
                );
                setAvailableModels(availableModels);
            } else {
                addErrorMessage(
                    "Error",
                    "Your portfolio has not been updated. Please try again later"
                );
            }
        },
    });

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Modal heading</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Portfolio based on {chosenFactor} factor will be erased and new
                portfolio {factor.Factor} will be created.
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Cancel
                </Button>
                <Button
                    variant="primary"
                    type="submit"
                    onClick={(e) => {
                        e.preventDefault();
                        mutation.mutate();
                    }}
                >
                    Use this model
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ConfirmationDialog;
