import { useFactors } from "../../../hooks/query/useFactors";
// import { useMeanQuantileQuery } from "../../../hooks/useBacktest";
import ReactECharts from "echarts-for-react";

export const LogReturnsByQuarter = ({
    userId,
    strategyId,
    factor,
}: {
    userId?: string;
    strategyId?: string;
    factor?: string;
}) => {
    const { useMeanQuantileQuery } = useFactors();
    const meanQuantileQuery = useMeanQuantileQuery(userId, strategyId, factor);
    //   useEffect(() => {
    //     if (query.data ) {
    //         setSelectedFactor(query.data[3].Factor);
    //     }

    // }, [query.data]);

    if (meanQuantileQuery.data) {
        const options = {
            xAxis: {
                type: "category",
                data: ["Q1 (Low Score)", "Q5 (High Score)"],
            },
            yAxis: {
                type: "value",
                name: "Performance",
                nameLocation: "middle",
                nameGap: 5,
                axisLabel: {
                    formatter: "",
                    margin: 5,
                },
            },
            series: [
                {
                    data: [
                        meanQuantileQuery.data?.Log_Return_Q1,
                        meanQuantileQuery.data?.Log_Return_Q5,
                    ],
                    type: "bar",
                    showBackground: true,
                    backgroundStyle: {
                        color: "rgba(180, 180, 180, 0.2)",
                    },
                },
            ],
            tooltip: {
                trigger: "axis",
                formatter: function (param: any) {
                    return `
                  <div>${param[0].marker}${param[0].name}</div>
                `;
                },
            },
        };

        return (
            <>
                {/* <FactorSelector factor={selectedFactor} onChange={setSelectedFactor} /> */}
                <ReactECharts option={options} />
            </>
        );
    }

    // if (meanQuantileQuery.error) {
    //     return (
    //         <div>
    //             Error fetching log returns by quarter!
    //         </div>
    //     );
    // }

    return (
        <>
            {/* <FactorSelector factor={selectedFactor} onChange={setSelectedFactor} /> */}
            <div>Loading...</div>
        </>
    );
};
