import { useFactors } from "../../../hooks/query/useFactors";
import ReactECharts from "echarts-for-react";
import { FactorStrategy } from "../../../api/schema";
import Card from "../../common/Card";
import { Placeholder } from "react-bootstrap";
import { toFixed } from "../../../utils/utils";

export const CumulativeReturnChart = ({
    userId,
    strategyId,
    factors,
    onFactorClick,
}: {
    factors: FactorStrategy[];
    userId: string;
    strategyId: string;
    onFactorClick: (series: string) => void;
}) => {
    const { useFactorCumulativeReturnQuery } = useFactors();
    const cumulativeReturnQuery = useFactorCumulativeReturnQuery(
        userId,
        strategyId,
        factors.map((f) => f.Factor).join(",")
    );
    if (cumulativeReturnQuery.data && factors.length > 0) {
        console.log(Object.keys(cumulativeReturnQuery.data));
        const options = {
            xAxis: {
                type: "time",
            },
            yAxis: {
                type: "value",
                name: "Performance",
                nameLocation: "middle",
                nameGap: 50,
                axisLabel: {
                    formatter: "{value}%",
                    margin: 5,
                },
            },
            series: Object.keys(cumulativeReturnQuery.data).map((f) => {
                return {
                    data: Object.keys(cumulativeReturnQuery.data[f]).map(
                        (date: any) => [
                            new Date(Number(date)),
                            cumulativeReturnQuery.data[f][date] * 100,
                        ]
                    ),
                    type: "line",
                    name: f,
                    showSymbol: false,
                };
            }),
            tooltip: {
                confine: true,
                trigger: "axis",
                formatter: function (param: any) {
                    return `Date: ${new Date(param[0].value[0]).toLocaleDateString()} <br/>
                ${param.map((p: any) => `<div style="display:flex; justify-content:space-between"><span style=margin-right:10px>${p.marker} ${p.seriesName}</span> <span>${p.value[1] < 0 ? "" : "+"}${toFixed(p.value[1])}%</span></div>`).join("")}
                `;
                },
            },
        };

        return (
            <Card>
                <h4>Cumulative Returns of Factors</h4>
                <ReactECharts
                    option={options}
                    notMerge={true}
                    onEvents={{
                        click: async (params: any) => {
                            console.log(params);
                            if (params.seriesName) {
                                onFactorClick(params.seriesName);
                            }
                        },
                    }}
                />
            </Card>
        );
    }

    if (cumulativeReturnQuery.error) {
        console.log(cumulativeReturnQuery.error);
        <Card>
            <h4>Cumulative Returns of Factors</h4>
            <div
                className="my-3"
                style={{
                    height: "300px",
                    display: "flex",
                }}
            >
                <div className="m-auto">
                    <h4>Failed to load data</h4>
                </div>
            </div>
        </Card>;
    }

    return (
        <Card>
            <div className="my-3">
                <Placeholder as="div" animation="glow">
                    <Placeholder
                        xs={12}
                        style={{
                            height: "300px",
                        }}
                    />
                </Placeholder>
            </div>
        </Card>
    );
};
