import { Apps } from "./Apps";
import { ReactComponent as CCFRIcon } from "../../../assets/images/ANP_logo_col.svg";
import { Link, NavLink } from "react-router-dom";
import { useModels } from "../../../hooks/query/useModel";
import { Menu as ReactMenu, MenuItem, SubMenu } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import styles from "./Menu.module.scss";

import {
    Button,
    ButtonGroup,
    Container,
    Dropdown,
    Navbar,
} from "react-bootstrap";
import { User } from "./User";

const Menu = () => {
    const { useModelsQuery } = useModels();
    const modelQuery = useModelsQuery();
    return (
        <Navbar expand="sm" className={styles.navbar} sticky="top">
            <Container fluid>
                <Navbar.Brand>
                    <Link to="/">
                        <CCFRIcon
                            style={{
                                height: "3rem",
                            }}
                        />
                    </Link>
                </Navbar.Brand>
                <Navbar.Toggle />
                <Navbar.Collapse className="justify-content-end">
                    <ReactMenu
                        menuButton={
                            <Dropdown as={ButtonGroup} className="mx-2">
                                <NavLink to="/" className="dropdown btn-group">
                                    <Button>Portfolios Dashboard</Button>
                                </NavLink>
                                <Dropdown.Toggle
                                    split
                                    id="dropdown-split-basic"
                                />
                            </Dropdown>
                        }
                    >
                        {modelQuery.data?.map((model) => (
                            <NavLink to={`/${model.modelId}`}>
                                <SubMenu label={model.name}>
                                    <MenuItem>
                                        <Link to={`/strategy/${model.modelId}`}>
                                            Edit Strategy
                                        </Link>
                                    </MenuItem>
                                    <MenuItem>
                                        <Link to={`/model/${model.modelId}`}>
                                            Edit Model
                                        </Link>
                                    </MenuItem>
                                </SubMenu>
                            </NavLink>
                        ))}
                    </ReactMenu>
                    <Apps />
                    <User />
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

export default Menu;
