import ReactECharts from "echarts-for-react";
import { RebalanceReturnsTypes, ReturnType } from "../../../api/schema";
import { useModels } from "../../../hooks/query/useModel";
import { toFixed } from "../../../utils/utils";
import { Col, Placeholder, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useMemo } from "react";

const getMin = (
    data: {
        [dates: string]: ReturnType;
    },
    type: "LongOnly" | "ShortOnly" | "LongShort" | "Strategy"
) => {
    const dates = Object.keys(data);

    return dates
        .map((d: string) => (data[d][type] || 0) * 100)
        .reduce((a, b) => Math.min(a, b), 0);
};

const getMax = (
    data: {
        [dates: string]: ReturnType;
    },
    type: "LongOnly" | "ShortOnly" | "LongShort" | "Strategy"
) => {
    const dates = Object.keys(data);
    return dates
        .map((d: string) => (data[d][type] || 0) * 100)
        .reduce((a, b) => Math.max(a, b), 0);
};

const getDistribution = (
    data: {
        [date: string]: ReturnType;
    },
    type: "LongOnly" | "ShortOnly" | "LongShort" | "Strategy"
) => {
    const min = getMin(data, type);
    const max = getMax(data, type);
    const binCount = 20;
    const binSize = (max - min) / binCount;
    const distribution = new Map<string, number>();
    for (let i = 0; i < binCount; i++) {
        distribution.set(
            `${min + binSize * i} - ${min + binSize * (i + 1)}`,
            0
        );
    }

    const dates = Object.keys(data);

    const longShorts = data as {
        [date: string]: ReturnType;
    };

    dates
        .map((d: string) => (longShorts[d][type] || 0) * 100)
        .forEach((v: number) => {
            for (let i = 0; i < binCount; i++) {
                if (v <= min + binSize * (i + 1)) {
                    const range = `${min + binSize * i} - ${min + binSize * (i + 1)}`;
                    distribution.set(range, (distribution.get(range) || 0) + 1);
                    break;
                }
            }
        });

    let histogram = [];
    for (let [key, value] of distribution.entries()) {
        histogram.push([key, value]);
    }

    return histogram;
};

const getOptions = (data: any) => {
    return {
        xAxis: [
            {
                type: "category",
                name: "Returns",
                nameLocation: "middle",
                data: data.map((v: any) => v[0]),
                axisTick: { show: false },
                axisLabel: { show: false },
                axisLine: { show: false },
            },
        ],
        yAxis: [
            {
                type: "value",
                name: "Frequency",
                nameLocation: "middle",
                nameGap: 20,
            },
        ],
        series: [
            {
                name: "Count",
                type: "bar",
                barWidth: "100%",
                data: data.map((v: any) => v[1]),
                label: {
                    show: true,
                    position: "top",
                },
            },
        ],
        tooltip: {
            trigger: "axis",
            axisPointer: {
                type: "shadow",
            },
            formatter: function (params: any) {
                const count = params[0].value;
                const [from, to] = params[0].name.split(" - ");
                return `
              <div>Frequency</div>
              <div><span style="margin-right:10px">Return ${toFixed(from)}% to ${toFixed(to)}%</span> <span><b>${count}</b></span></div>
            `;
            },
        },
    };
};

export const RebalanceReturns = () => {
    let { modelId = "" } = useParams();
    const { useModelQuery, useModelReturnsQuery } = useModels();
    const modelQuery = useModelQuery(modelId);
    const model = modelQuery.data;
    const returnsQuery = useModelReturnsQuery(model?.userId, model?.modelId);
    const dataMap = new Map<string, number | string>();
    const months = [
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
    ];
    const type = "Strategy" as RebalanceReturnsTypes;
    const values = useMemo(() => {
        return returnsQuery.data
            ? Object.keys(returnsQuery.data).map(
                  (date: string) => returnsQuery.data[date][type]
              )
            : [];
    }, [returnsQuery.data, type]);

    const years = useMemo(() => {
        if (!returnsQuery.data) {
            return [];
        }

        return Array.from(
            (Object.keys(returnsQuery.data) || []).reduce(
                (acc: any, k: string) => {
                    const date = new Date(k);
                    acc.add(date.getFullYear().toString());
                    return acc;
                },
                new Set()
            )
        ).sort((a: any, b: any) => a - b) as string[];
    }, [returnsQuery.data]);

    if (returnsQuery.data && modelQuery.data) {
        const longShortsDistribution = getDistribution(returnsQuery.data, type);

        years.forEach((year: string) => {
            months.forEach((month: string) => {
                dataMap.set(`${year}-${month}`, "-");
            });
        });

        Object.keys(returnsQuery.data).forEach((k: string) => {
            const date = new Date(k);
            const value = returnsQuery.data[k][type] || 0;
            dataMap.set(`${date.getFullYear()}-${date.getMonth() + 1}`, value);
        });

        const data: any[] = [];
        dataMap.forEach((v, k) => {
            const [year, month] = k.split("-").map((v: string) => parseInt(v));
            const i = years.findIndex((y: string) => y === year.toString());
            data.push([month - 1, i, toFixed(Number(v) * 100, 2)]);
        });
        const min = Math.min(...(values.filter(Boolean) as number[])) * 100;
        const max = Math.max(...(values.filter(Boolean) as number[])) * 100;

        const option = {
            tooltip: {
                position: "top",
                valueFormatter: function (value: any) {
                    return `${value}%`;
                },
            },
            grid: {
                height: "50%",
                top: "10%",
            },
            xAxis: {
                type: "category",
                data: months,
                splitArea: {
                    show: true,
                },
            },
            yAxis: {
                type: "category",
                data: years,
                splitArea: {
                    show: true,
                },
            },
            visualMap: {
                min: min,
                max: max,
                calculable: true,
                orient: "horizontal",
                left: "center",
                bottom: "15%",
                inRange: {
                    color: ["#FF0000", "#00FF00"],
                },
            },
            series: [
                {
                    name: "Returns",
                    type: "heatmap",
                    data: data,
                    label: {
                        show: true,
                    },
                },
            ],
        };

        if (data.length > 0) {
            return (
                <>
                    <Row className="my-3">
                        <Col sm={6}>
                            <h3>Monthly Returns [%]</h3>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                }}
                            >
                                <div
                                    style={{
                                        justifyContent: "center",
                                    }}
                                >
                                    <ReactECharts option={option} />
                                </div>
                            </div>
                        </Col>
                        <Col sm={6}>
                            <h3>Distribution of Monthly Returns</h3>
                            <ReactECharts
                                option={getOptions(longShortsDistribution)}
                            />
                        </Col>
                    </Row>
                </>
            );
        }
    }

    if (returnsQuery.error) {
        return <div> {returnsQuery.error.message}</div>;
    }

    return (
        <>
            <Row className="my-3">
                <Col sm={6}>
                    <h3>Monthly Returns [%]</h3>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        <div
                            style={{
                                justifyContent: "center",
                            }}
                        >
                            <Placeholder as="div" animation="glow">
                                <Placeholder
                                    xs={12}
                                    style={{
                                        height: "300px",
                                    }}
                                />
                            </Placeholder>
                        </div>
                    </div>
                </Col>
                <Col sm={6}>
                    <h3>Portfolio Returns</h3>
                    <Placeholder as="div" animation="glow">
                        <Placeholder
                            xs={12}
                            style={{
                                height: "300px",
                            }}
                        />
                    </Placeholder>
                </Col>
            </Row>
        </>
    );
};
