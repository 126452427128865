import { useQuery } from "@tanstack/react-query";
import { StockDetailsType } from "../../api/schema";
import { useStockDetailsApi } from "../api/aws/useStockDetailsApi";

export const useStockDetails = () => {
    const { fetchStockDetails } = useStockDetailsApi();

    const useStockDetailsQuery = <TData = StockDetailsType[]>(
        tickers: string[]
    ) =>
        useQuery<StockDetailsType[], Error, TData>({
            queryKey: ["stockDetails", tickers.join(",")],
            queryFn: () => fetchStockDetails(tickers),
            enabled: tickers.length > 0,
        });

    return {
        useStockDetailsQuery,
    };
};
