import {
    stockDetailsResponseSchema,
    StockDetailsResponseType,
} from "../../../api/schema";
import { useAuthApi } from "../../useAuthApi";
import { HTTP_METHODS } from "../useApi";

export const useStockDetailsApi = () => {
    const { sendAuthGuardedRequest } = useAuthApi();

    const fetchStockDetails = async (stocks: string[]) => {
        const response = await sendAuthGuardedRequest<StockDetailsResponseType>(
            HTTP_METHODS.GET,
            `/stocks?tickers=${stocks.join(",")}`
        );

        const validation = stockDetailsResponseSchema.safeParse(response.data);
        if (!validation.success) {
            console.log(validation.error);
            throw new Error(JSON.stringify(validation.error));
        }

        return response.data.results;
    };

    return {
        fetchStockDetails,
    };
};
