import {
    benchmarkResponseSchema,
    BenchmarkResponseType,
    universeSchema,
    universesSchema,
    UniverseType,
} from "../../../api/schema";
import { useAuthApi } from "../../useAuthApi";
import { HTTP_METHODS } from "../useApi";

export const useUniverseApi = () => {
    const { sendAuthGuardedRequest } = useAuthApi();

    const fetchUniverses = async () => {
        const universes = await sendAuthGuardedRequest<UniverseType[]>(
            HTTP_METHODS.GET,
            `/universe`
        );

        const validation = universesSchema.safeParse(universes.data);
        if (!validation.success) {
            throw new Error(JSON.stringify(validation.error));
        }

        return universes.data;
    };

    const fetchUniverse = async (universeName: string) => {
        const universe = await sendAuthGuardedRequest<UniverseType>(
            HTTP_METHODS.GET,
            `/universe/${universeName}`
        );

        const validation = universeSchema.safeParse(universe.data);
        if (!validation.success) {
            console.log(validation.error);
            throw new Error(JSON.stringify(validation.error));
        }

        return universe.data;
    };

    const fetchBenchmarks = async () => {
        const benchmarks = await sendAuthGuardedRequest<BenchmarkResponseType>(
            HTTP_METHODS.GET,
            `/benchmark`
        );

        const validation = benchmarkResponseSchema.safeParse(benchmarks.data);
        if (!validation.success) {
            console.log(validation.error);
            throw new Error(JSON.stringify(validation.error));
        }

        return benchmarks.data;
    };

    return {
        fetchUniverses,
        fetchUniverse,
        fetchBenchmarks,
    };
};
