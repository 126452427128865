import { useMutation } from "@tanstack/react-query";
import { FactorModelType } from "../api/schema";
import { useModelApi } from "./api/aws/useModelApi";

export const useModelUpdate = ({
    onBeforeMutation,
    handleError,
    handleSuccess,
}: {
    onBeforeMutation?: () => void;
    handleError?: (error: any) => void;
    handleSuccess?: () => void;
}) => {
    const { updateModel } = useModelApi();
    const changeModel = useMutation({
        mutationFn: async (newModel: FactorModelType) => {
            if (onBeforeMutation) {
                onBeforeMutation();
            }
            if (newModel && updateModel) {
                await updateModel(newModel.userId, newModel.modelId, newModel);
            }
        },
        onSuccess: () => {
            if (handleSuccess) {
                handleSuccess();
            }
        },
        onError: (error) => {
            if (handleError) {
                handleError(error);
            }
        },
    });

    return {
        updateModel: changeModel.mutate,
    };
};
