import AuthClientStore from "../../api/authClientStore";
import apiANP from "./apiANP";
import apiAWS from "./aws/apiAWS";

export type HttpMethod = "GET" | "POST" | "PATCH" | "PUT" | "DELETE";
export const HTTP_METHODS = {
    GET: "GET",
    POST: "POST",
    PUT: "PUT",
    DELETE: "DELETE",
    PATCH: "PATCH",
} as const;

export interface RequestParams {
    headers?: Record<string, string>;
    query?: Record<string, string | number>;
    body?: any;
}

export interface ApiResponse<T> {
    status: number;
    data: T;
    message?: string;
}

export interface ApiMethods {
    getCall<T>(url: string, params?: RequestParams): Promise<ApiResponse<T>>;
    postCall<T>(url: string, params?: RequestParams): Promise<ApiResponse<T>>;
    putCall<T>(url: string, params?: RequestParams): Promise<ApiResponse<T>>;
    deleteCall<T>(url: string, params?: RequestParams): Promise<ApiResponse<T>>;
    patchCall<T>(url: string, params?: RequestParams): Promise<ApiResponse<T>>;
}

const sendAWSApiRequest = <T>(
    method: HttpMethod,
    path: string,
    body?: any,
    useToken = true,
    init?: RequestInit
): Promise<ApiResponse<T>> => {
    const authToken = AuthClientStore.getAccessToken();
    if (!authToken) {
        throw new Error("No auth token found");
    }

    switch (method) {
        case "GET":
            return apiAWS.getCall<T>(path);
        case "POST":
            return apiAWS.postCall<T>(path, {
                body,
            });
        case "PATCH":
            return apiAWS.patchCall<T>(path, {
                body,
            });
        case "DELETE":
            return apiAWS.deleteCall<T>(path);
        default:
            return apiAWS.getCall<T>(path);
    }
};

const sendANPApiRequest = <T>(
    method: HttpMethod,
    path: string,
    body?: any,
    useToken = true,
    init?: RequestInit
): Promise<ApiResponse<T>> => {
    const authToken = AuthClientStore.getAccessToken();
    if (!authToken) {
        throw new Error("No auth token found");
    }

    switch (method) {
        case "GET":
            return apiANP.getCall<T>(path, {
                headers: {
                    ...(init?.headers as Record<string, string>),
                    ...(useToken && { Authorization: `Bearer ${authToken}` }),
                },
            });
        case "POST":
            return apiANP.postCall<T>(path, {
                headers: {
                    ...(init?.headers as Record<string, string>),
                    ...(useToken && { Authorization: `Bearer ${authToken}` }),
                },
                body,
            });
        default:
            return apiANP.getCall<T>(path, {
                headers: {
                    ...(useToken && { Authorization: `Bearer ${authToken}` }),
                },
            });
    }
};

export const useApi = () => {
    return { sendAWSApiRequest, sendANPApiRequest };
};
