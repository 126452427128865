import { useAuth } from "../../../hooks/useAuth";
import { useEffect } from "react";
import { getANPHost } from "../../../utils/envHandler";

const RedirectUrl = ({ url }: {
    url: string;

}) => {
    useEffect(() => {
      window.location.href = url;
    }, [url]);
  
    return <></>
  };

const ProtectedRoute = ({ children }: {
    children: React.ReactElement;
}): React.ReactElement => {
  const { user } = useAuth();

  useEffect(() => {
    console.log(user?.email);
  }, [user]);

  if (user === null) {
    // user is not authenticated
    return <RedirectUrl url={getANPHost()} />;
  } else {
    return children;
  }
};

export default ProtectedRoute;