import { Button, Form, Modal } from "react-bootstrap";
import { FactorModelType } from "../../../../api/schema";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useBusy } from "../../../../hooks/useBusy";
import { useMessages } from "../../../../hooks/useMessages";
import { useAuthApi } from "../../../../hooks/useAuthApi";
import { useModelApi } from "../../../../hooks/api/aws/useModelApi";

const OverwriteModelDialog = ({
    show,
    handleClose,
    availableModels,
    factor,
    direction,
}: {
    show: boolean;
    handleClose: () => void;
    availableModels: FactorModelType[];
    factor: string;
    direction: number;
}) => {
    const { recalculateModel, updateModel } = useModelApi();
    const [selectedModel, setSelectedModel] = useState<string | null>(null);
    const { setIsBusy } = useBusy();
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    useEffect(() => {
        setSelectedModel(availableModels[0]?.modelId || "");
    }, [availableModels]);
    const { me } = useAuthApi();
    const userPromise = me(() => {});
    const { addErrorMessage, addInfoMessage } = useMessages();
    const overwriteExistingModel = useMutation({
        mutationFn: async () => {
            const model = availableModels.find(
                (model) => model.modelId === selectedModel
            );
            if (!model) {
                throw new Error("Model not found");
            }
            setIsBusy(true);
            const user = await userPromise;
            const updatedModel = await updateModel(
                user?.pk || "",
                selectedModel || "",
                {
                    ...model,
                    factor: {
                        [factor]: 1,
                    },
                }
            );
            await recalculateModel(
                user?.pk || "",
                selectedModel || "",
                model.strategyId || "",
                {
                    ascending: direction === -1,
                }
            );
            return updatedModel;
        },
        onSuccess: (data: FactorModelType) => {
            setIsBusy(false);
            addInfoMessage(
                "Portfolio updated",
                "Factor model of your portfolio has been successfully updated"
            );
            queryClient.invalidateQueries({ queryKey: ["models"] });
            navigate(`/${data.modelId}`);
        },
        onError: (error: any) => {
            addErrorMessage(
                "Error",
                "Your portfolio has not been updated. Please try again later"
            );
        },
    });

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Overwrite existing</Modal.Title>
            </Modal.Header>
            <div className="m-2">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Overwrite my portfolio:</Form.Label>
                    <Form.Select
                        aria-label="Model to overwrite"
                        onChange={(e) => setSelectedModel(e.target.value)}
                    >
                        {availableModels.map((model) => (
                            <>
                                <option key={model.modelId}>
                                    {model.name}
                                </option>
                            </>
                        ))}
                    </Form.Select>
                </Form.Group>
            </div>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Cancel
                </Button>
                <Button
                    variant="primary"
                    type="submit"
                    onClick={(e) => {
                        e.preventDefault();
                        overwriteExistingModel.mutate();
                    }}
                >
                    Overwrite portfolio
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default OverwriteModelDialog;
