import { Button, Form, Modal } from "react-bootstrap";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { FactorModelType } from "../../../api/schema";
import { useNavigate } from "react-router-dom";
import Card from "../../common/Card";
import { useState } from "react";
import { useModelApi } from "../../../hooks/api/aws/useModelApi";

const EmptySlot = () => {
    const { createModel } = useModelApi();
    const queryClient = useQueryClient();
    const [showDialog, setShowDialog] = useState(false);
    const [modelName, setModelName] = useState("");
    const navigate = useNavigate();
    const mutation = useMutation({
        mutationFn: async () => {
            return await createModel(modelName);
        },
        onSuccess: (data: FactorModelType) => {
            queryClient.invalidateQueries({ queryKey: ["models"] });
            navigate(`/model/${data.modelId}`);
        },
        onError: (error) => {},
    });

    return (
        <>
            <Modal show={showDialog}>
                <Modal.Header closeButton onHide={() => setShowDialog(false)}>
                    <Modal.Title>Create</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                    >
                        <Form.Label>Portfolio Name</Form.Label>
                        <Form.Control
                            type="name"
                            value={modelName}
                            onChange={(e) => {
                                setModelName(e.target.value);
                            }}
                        />
                    </Form.Group>
                </Modal.Body>

                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => setShowDialog(false)}
                    >
                        Close
                    </Button>
                    <Button
                        variant="primary"
                        disabled={!modelName}
                        onClick={() => {
                            mutation.mutate();
                            setShowDialog(false);
                        }}
                    >
                        Create
                    </Button>
                </Modal.Footer>
            </Modal>

            <Card>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        height: "100%",
                    }}
                >
                    Place for your new portfolio
                    <Button
                        variant="primary"
                        onClick={() => setShowDialog(true)}
                    >
                        +
                    </Button>
                </div>
            </Card>
        </>
    );
};

export default EmptySlot;
