import { deleteCookie, getCookie, setCookie } from "../utils/utils";

const REFRESH_TOKEN_KEY = "refreshToken";
const ACCESS_TOKEN_KEY = "accessToken";

class AuthClientStore {
    static getAccessToken() {
        return getCookie(ACCESS_TOKEN_KEY);
    }

    static setAccessToken(token: string) {
        setCookie(ACCESS_TOKEN_KEY, token);
    }

    static removeAccessToken(): void {
        deleteCookie(ACCESS_TOKEN_KEY);
    }

    static getRefreshToken() {
        return getCookie(REFRESH_TOKEN_KEY);
    }

    static setRefreshToken(token: string) {
        setCookie(REFRESH_TOKEN_KEY, token);
    }

    static removeRefreshToken(): void {
        deleteCookie(REFRESH_TOKEN_KEY);
    }
}

export default AuthClientStore;
