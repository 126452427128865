import { useQuery } from "@tanstack/react-query";
import {
    F3FamaFrenchType,
    F5FamaFrenchType,
    FactorCumulativeReturnItem,
    FactorDetailsType,
    FactorListType,
    FactorMeanQuantileType,
    FactorMimickingZoomResponseType,
    FactorMimickingZoomType,
    FactorMomentumItem,
    FactorPerformanceReducedType,
    FactorRankingItem,
    LongShortCumulativeType,
    ModelAccuracyType,
} from "../../api/schema";
import { useFactorApi } from "../api/aws/useFactorApi";

export const useFactors = () => {
    const {
        factorDetails,
        factorList,
        fetchBottomFactors,
        fetchF3FamaFrench,
        fetchF5FamaFrench,
        fetchFactorCumulativeReturn,
        fetchFactorListById,
        fetchFactorMomentum,
        fetchFactorRanking,
        fetchLongShortCumulative,
        fetchMeanQuantiles,
        fetchModelAccuracy,
        fetchTopFactors,
        zoomFactorMimickingData,
    } = useFactorApi();

    const useFactoryRankingQuery = <TData = FactorRankingItem[]>(
        userId?: string,
        portfolioId?: string,
        select?: (strategies: FactorRankingItem[]) => TData
    ) =>
        useQuery<FactorRankingItem[], Error, TData>({
            queryKey: ["factorRanking", userId, portfolioId],
            queryFn: () => fetchFactorRanking(userId || "", portfolioId || ""),
            select,
            enabled: !!userId && !!portfolioId,
        });

    const useFactorMomentumQuery = <TData = FactorMomentumItem[]>(
        userId?: string,
        strategyId?: string,
        factor?: string,
        select?: (strategies: FactorMomentumItem[]) => TData
    ) =>
        useQuery<FactorMomentumItem[], Error, TData>({
            queryKey: ["factorMomentum", userId, strategyId, factor],
            queryFn: () =>
                fetchFactorMomentum(
                    userId || "",
                    strategyId || "",
                    factor || ""
                ),
            select,
            enabled: !!userId && !!strategyId && !!factor,
        });

    const useFactorCumulativeReturnQuery = <TData = FactorCumulativeReturnItem>(
        userId?: string,
        strategyId?: string,
        factors?: string,
        select?: (strategies: FactorCumulativeReturnItem) => TData
    ) =>
        useQuery<FactorCumulativeReturnItem, Error, TData>({
            queryKey: ["factorCumulativeReturn", userId, strategyId, factors],
            queryFn: () =>
                fetchFactorCumulativeReturn(
                    userId || "",
                    strategyId || "",
                    factors || ""
                ),
            select,
            enabled:
                !!userId && !!strategyId && !!factors && factors.length > 0,
        });

    const useFactorsListQuery = <TData = FactorListType[]>(
        select?: (strategies: FactorListType[]) => TData
    ) =>
        useQuery<FactorListType[], Error, TData>({
            queryKey: ["factorList"],
            queryFn: () => factorList(),
            select,
        });

    const useFactorDetailsQuery = <TData = FactorDetailsType[]>(
        select?: (strategies: FactorDetailsType[]) => TData
    ) =>
        useQuery<FactorDetailsType[], Error, TData>({
            queryKey: ["factorDetails"],
            queryFn: () => factorDetails(),
            select,
        });

    const useF3FamaFrenchQuery = <TData = F3FamaFrenchType>(
        select?: (data: F3FamaFrenchType) => TData
    ) =>
        useQuery<F3FamaFrenchType, Error, TData>({
            queryKey: ["famafrench3f"],
            queryFn: () => fetchF3FamaFrench(),
            select,
        });

    const useF5FamaFrenchQuery = <TData = F5FamaFrenchType>(
        select?: (data: F5FamaFrenchType) => TData
    ) =>
        useQuery<F5FamaFrenchType, Error, TData>({
            queryKey: ["famafrench5f"],
            queryFn: () => fetchF5FamaFrench(),
            select,
        });

    const useMeanQuantileQuery = <TData = FactorMeanQuantileType>(
        userId?: string,
        strategyId?: string,
        factor?: string,
        select?: (strategies: FactorMeanQuantileType) => TData
    ) =>
        useQuery<FactorMeanQuantileType, Error, TData>({
            queryKey: ["mean-quantile", strategyId, factor],
            queryFn: () =>
                fetchMeanQuantiles(
                    userId || "",
                    strategyId || "",
                    factor || ""
                ),
            select,
            enabled: !!userId && !!strategyId && !!factor,
        });

    const useModelAccuracyQuery = <TData = ModelAccuracyType>(
        userId?: string,
        strategyId?: string,
        factor?: string,
        select?: (strategies: ModelAccuracyType) => TData
    ) =>
        useQuery<ModelAccuracyType, Error, TData>({
            queryKey: ["model-accuracy", strategyId, factor],
            queryFn: () =>
                fetchModelAccuracy(
                    userId || "",
                    strategyId || "",
                    factor || ""
                ),
            select,
            enabled: !!userId && !!strategyId && !!factor,
        });

    const useNormalizedMaeOverTimeQuery = <TData = ModelAccuracyType>(
        userId?: string,
        strategyId?: string,
        factor?: string,
        select?: (strategies: ModelAccuracyType) => TData
    ) => {
        return useModelAccuracyQuery(
            userId,
            strategyId,
            factor,
            (data) => data.accuracy_normalized_over_time.MAE
        );
    };

    const useNormalizedR2OverTimeQuery = <TData = ModelAccuracyType>(
        userId?: string,
        strategyId?: string,
        factor?: string,
        select?: (strategies: ModelAccuracyType) => TData
    ) => {
        return useModelAccuracyQuery(
            userId,
            strategyId,
            factor,
            (data) => data.accuracy_normalized_over_time.R2
        );
    };

    const useLongShortCumulativeQuery = <TData = LongShortCumulativeType[]>(
        userId?: string,
        strategyId?: string,
        factor?: string,
        select?: (strategies: LongShortCumulativeType[]) => TData
    ) =>
        useQuery<LongShortCumulativeType[], Error, TData>({
            queryKey: ["longShortCumulative", userId, strategyId, factor],
            queryFn: () =>
                fetchLongShortCumulative(
                    userId || "",
                    strategyId || "",
                    factor || ""
                ),
            select,
            enabled: !!userId && !!strategyId && !!factor,
        });

    const useTopFactorsQuery = <TData = FactorPerformanceReducedType>(
        select?: (factorPerformance: FactorPerformanceReducedType) => TData
    ) =>
        useQuery<FactorPerformanceReducedType, Error, TData>({
            queryKey: ["topFactors"],
            queryFn: () => fetchTopFactors(),
            select,
        });

    const useWorstFactorsQuery = <TData = FactorPerformanceReducedType>(
        select?: (factorPerformance: FactorPerformanceReducedType) => TData
    ) =>
        useQuery<FactorPerformanceReducedType, Error, TData>({
            queryKey: ["worstFactors"],
            queryFn: () => fetchBottomFactors(),
            select,
        });

    const useZoomFactorMimickingData = <
        TData = FactorMimickingZoomResponseType,
    >(
        payload: FactorMimickingZoomType
    ) =>
        useQuery<FactorMimickingZoomResponseType, Error, TData>({
            queryKey: [
                "zoomFactorMimickingData",
                payload?.strategyId,
                payload?.from,
                payload?.to,
            ],
            queryFn: () => zoomFactorMimickingData(payload),
        });

    const useZoomedFactorsListQuery = <TData = FactorRankingItem[]>(
        strategyId?: string,
        select?: (strategies: FactorRankingItem[]) => TData
    ) =>
        useQuery<FactorRankingItem[], Error, TData>({
            queryKey: ["factorList", strategyId],
            queryFn: () => fetchFactorListById(strategyId || ""),
            select,
            enabled: !!strategyId,
        });

    return {
        useFactoryRankingQuery,
        useFactorMomentumQuery,
        useFactorCumulativeReturnQuery,
        useFactorsListQuery,
        useFactorDetailsQuery,
        useF3FamaFrenchQuery,
        useF5FamaFrenchQuery,
        useMeanQuantileQuery,
        useModelAccuracyQuery,
        useNormalizedMaeOverTimeQuery,
        useNormalizedR2OverTimeQuery,
        useLongShortCumulativeQuery,
        useTopFactorsQuery,
        useWorstFactorsQuery,
        useZoomFactorMimickingData,
        useZoomedFactorsListQuery,
    };
};
