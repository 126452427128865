import { createContext, useCallback, useEffect, useState } from "react";
import { HttpMethod } from "../hooks/api/useApi";
import { useAuthApi } from "../hooks/useAuthApi";
import useCachedAsync from "../hooks/utils/useCachedAsync";
import { useLocalStorage } from "../hooks/useLocalStorage";
import { getANPHost } from "../utils/envHandler";

export type User = {
    email: string;
    pk: string;
    permissions: string[];
} | null;

type AuthContextType = {
    isVerifing: boolean;
    isAuthenticated: boolean;
    logout: () => void;
    sendAuthGuardedRequest: (
        method: HttpMethod,
        path: string,
        body?: any,
        init?: RequestInit
    ) => Promise<any>;
    user: User | undefined;
};

export const AuthContext = createContext<AuthContextType | undefined>(
    undefined
);

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
    const [isVerifing, setIsVerifing] = useState(true);
    const {
        sendAuthGuardedRequest,
        me: authMe,
        logout: unauthMe,
    } = useAuthApi();
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [storedUser, setLocalStorage] = useLocalStorage("user", undefined);
    const [user, setUser] = useState<User>(storedUser);

    const test = useCallback(async () => {
        try {
            const user = await authMe(() => {
                setIsAuthenticated(false);
            });
            setIsVerifing(false);
            setUser(user);
            setIsAuthenticated(true);
            setLocalStorage(user);
        } catch (e) {
            setIsVerifing(false);
            setIsAuthenticated(false);
            setUser(null);
            setLocalStorage(undefined);
        }
    }, [authMe, setLocalStorage]);
    const { execute } = useCachedAsync(test, 1000);

    const logout = useCallback(() => {
        unauthMe();
        setIsAuthenticated(false);
        setUser(null);
        setLocalStorage(undefined);

        window.location.href = getANPHost();
    }, [unauthMe, setLocalStorage]);

    useEffect(() => {
        execute();
    }, [execute]);

    return (
        <AuthContext.Provider
            value={{
                isAuthenticated,
                isVerifing,
                sendAuthGuardedRequest,
                user,
                logout,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};
