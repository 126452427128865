import { FactorStrategy } from "../../../../api/schema";
import { useFactors } from "../../../../hooks/query/useFactors";
import { toFixed } from "../../../../utils/utils";
import ReactECharts from "echarts-for-react";

const NormalizedR2 = ({ factor }: { factor: FactorStrategy }) => {
    const { useNormalizedR2OverTimeQuery } = useFactors();
    const modelAccuracyQuery = useNormalizedR2OverTimeQuery(
        factor.userId,
        factor.PK,
        factor.Factor
    );

    if (modelAccuracyQuery.data) {
        const options = {
            xAxis: {
                type: "time",
            },
            yAxis: {
                type: "value",
                boundaryGap: [0, "100%"],
                min: function (value: any) {
                    return toFixed(value.min - 0.01);
                },
                max: function (value: any) {
                    console.log("max");
                    console.log(value);
                    return toFixed(value.max + 0.01);
                },
            },
            series: [
                {
                    data: Object.keys(modelAccuracyQuery.data).map(
                        (date: any) => [
                            new Date(date),
                            modelAccuracyQuery.data[date],
                        ]
                    ),
                    type: "line",
                    name: "R2",
                    showSymbol: false,
                },
            ],
            tooltip: {
                trigger: "axis",
            },
        };
        return <ReactECharts option={options} />;
    }

    return null;
};

export default NormalizedR2;
