import {
    BacktestChartApiResponse,
    BacktestChartItem,
    backtestChartItemsSchema,
    BacktestReportResponse,
    backtestReportSchema,
    DrawdownResponseType,
    drawdownSchema,
    FactorModelResponseType,
    factorModelSchema,
    FactorModelType,
    NewModel,
    rebalanceReturnsResponseSchema,
    RebalanceReturnsResponseType,
} from "../../../api/schema";
import { AwsRoutes } from "./AwsRoutes";
import { HTTP_METHODS } from "../useApi";
import { useAuthApi } from "../../useAuthApi";

export const useModelApi = () => {
    const { sendAuthGuardedRequest } = useAuthApi();

    const createModel = async (name?: string) => {
        try {
            const model = await sendAuthGuardedRequest<{
                success: boolean;
                url: string;
                data: FactorModelType;
            }>(HTTP_METHODS.POST, "/model", {
                name: name,
            });
            return model.data.data;
        } catch (error) {
            console.log(error);
            throw new Error("Model was not created");
        }
    };

    const fetchAllPortfolios = async () => {
        const response = await sendAuthGuardedRequest<FactorModelResponseType>(
            HTTP_METHODS.GET,
            AwsRoutes.ALL_PORTFOLIOS
        );

        const models = response.data.results.filter((model) => {
            const validation = factorModelSchema.safeParse(model);
            if (!validation.success) {
                console.error(validation.error);
                return false;
            }
            return true;
        });

        return models;
    };

    const fetchModelReport = async (userId?: string, modelId?: string) => {
        const backtestReport =
            await sendAuthGuardedRequest<BacktestReportResponse>(
                HTTP_METHODS.GET,
                `/model/${userId}/${modelId}/table`
            );
        const validation = backtestReportSchema.safeParse(backtestReport.data);
        if (!validation.success) {
            console.log(validation.error);
            throw new Error(JSON.stringify(validation.error));
        }

        return backtestReport.data;
    };

    const fetchModelTimeseries = async (userId: string, modelId: string) => {
        const response = await sendAuthGuardedRequest<
            BacktestChartApiResponse | {}
        >(HTTP_METHODS.GET, `/model/${userId}/${modelId}/chart`);
        let data: BacktestChartItem[] = [];

        if (Object.keys(response.data).length > 0) {
            const backtestChartResponse =
                response.data as BacktestChartApiResponse;
            const indexes = Object.keys(backtestChartResponse.LongTheShort);
            data = indexes.map((index: string) => {
                return {
                    date: index,
                    LongOnly: backtestChartResponse.LongOnly[index],
                    ShortOnly: backtestChartResponse.ShortOnly[index],
                    LongTheShort: backtestChartResponse.LongTheShort[index],
                    LongShort: backtestChartResponse.LongShort[index],
                    Universe: backtestChartResponse.Universe[index],
                    SP500: backtestChartResponse.SP500[index],
                    SP100: backtestChartResponse.SP100[index],
                    Strategy: backtestChartResponse.Strategy[index],
                    FamaSMB_5f: backtestChartResponse.FamaSMB_5f[index],
                    FamaRMV: backtestChartResponse.FamaRMV[index],
                    FamaCMA: backtestChartResponse.FamaCMA[index],
                    FamaHML: backtestChartResponse.FamaHML[index],
                    "FamaMkt-RF": backtestChartResponse["FamaMkt-RF"][index],
                    FamaSMB_3f: backtestChartResponse.FamaSMB_3f[index],
                } as BacktestChartItem;
            });
        }

        const validation = backtestChartItemsSchema.safeParse(data);
        if (!validation.success) {
            console.log(validation.error);
            throw new Error(JSON.stringify(validation.error));
        }

        const backtestChartData = data as BacktestChartItem[];
        return backtestChartData;
    };

    const fetchModelDrawdown = async (userId: string, modelId: string) => {
        const response = await sendAuthGuardedRequest<DrawdownResponseType>(
            HTTP_METHODS.GET,
            `/model/${userId}/${modelId}/drawdown`
        );

        if (Object.keys(response).length > 0) {
            const validation = drawdownSchema.safeParse(response.data);
            if (!validation.success) {
                console.log(validation.error);
                throw new Error(JSON.stringify(validation.error));
            }
        }

        return response.data;
    };

    const fetchModelReturns = async (userId: string, strategyId: string) => {
        const rebalanceReturns =
            await sendAuthGuardedRequest<RebalanceReturnsResponseType>(
                HTTP_METHODS.GET,
                `/model/${userId}/${strategyId}/returns`
            );

        const validation = rebalanceReturnsResponseSchema.safeParse(
            rebalanceReturns.data
        );
        if (!validation.success) {
            console.log(validation.error);
            throw new Error(JSON.stringify(validation.error));
        }

        return rebalanceReturns.data.results;
    };

    const fetchModelCurrentReturns = async (
        userId: string,
        strategyId: string
    ) => {
        const rebalanceReturns =
            await sendAuthGuardedRequest<RebalanceReturnsResponseType>(
                HTTP_METHODS.GET,
                `/model/${userId}/${strategyId}/current-returns`
            );

        const validation = rebalanceReturnsResponseSchema.safeParse(
            rebalanceReturns.data
        );
        if (!validation.success) {
            console.log(validation.error);
            throw new Error(JSON.stringify(validation.error));
        }

        return rebalanceReturns.data.results;
    };

    const fetchScore = async (
        userId: string,
        modelId: string,
        date: string
    ) => {
        const scoreCSV = await sendAuthGuardedRequest<string[]>(
            HTTP_METHODS.GET,
            `/model/${userId}/${modelId}/score/${date}`
        );
        return scoreCSV.data;
    };

    const recalculateModel = async (
        userId: string,
        modelId: string,
        strategyId: string,
        modelData?: NewModel
    ) => {
        const backtestResponse = await sendAuthGuardedRequest(
            HTTP_METHODS.POST,
            `/model/${userId}/${modelId}/recalculate`,
            {
                ...modelData,
                strategyId,
            }
        );

        return backtestResponse;
    };

    // TO-DO: WHY IS THIS FUNCTION NAMED updatePortfolioTitle?
    const updatePortfolioTitle = async (portfolioId: string, title: string) => {
        await sendAuthGuardedRequest(
            HTTP_METHODS.PATCH,
            `/strategies/${portfolioId}`,
            {
                "strategy-name": title,
            }
        );
    };

    const updateModel = async (
        userId: string,
        modelId: string,
        body: string | FactorModelType
    ) => {
        const payload =
            typeof body === "string"
                ? {
                      factor: {
                          [body]: 1,
                      },
                  }
                : body;
        const model = await sendAuthGuardedRequest<{
            success: boolean;
            data: FactorModelType;
        }>(HTTP_METHODS.PATCH, `/model/${userId}/${modelId}`, payload);
        return model.data.data;
    };

    const removeModel = async (userId: string, modelId: string) => {
        await sendAuthGuardedRequest(
            HTTP_METHODS.DELETE,
            `/model/${userId}/${modelId}`
        );
    };

    return {
        createModel,
        fetchAllPortfolios,
        fetchModelReport,
        fetchModelTimeseries,
        fetchModelDrawdown,
        fetchModelReturns,
        fetchModelCurrentReturns,
        fetchScore,
        recalculateModel,
        removeModel,
        updatePortfolioTitle,
        updateModel,
    };
};
