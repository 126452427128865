import { getANPHost } from "../../utils/envHandler";
import { ApiMethods, ApiResponse, HttpMethod, RequestParams } from "./useApi";

async function fetchApi<T>(method: HttpMethod, url: string, params?: RequestParams): Promise<ApiResponse<T>> {
    const options: RequestInit = {
      method,
      headers: params?.headers,
      body: params?.body ? JSON.stringify(params.body) : undefined,
    };
  
    const response = await fetch(url, options);
    const data = await response.json();
  
    return {
      status: response.status,
      data: data as T,
    };
  }

const apiANP: ApiMethods = {
    async getCall<T>(path: string, params?: RequestParams) {
      return fetchApi<T>("GET", `${getANPHost()}${path}`, params);
    },
    async postCall<T>(path: string, params?: RequestParams) {
      return fetchApi<T>("POST", `${getANPHost()}${path}`, params);
    },
    async putCall<T>(path: string, params?: RequestParams) {
      return fetchApi<T>("PUT", `${getANPHost()}${path}`, params);
    },
    async deleteCall<T>(path: string, params?: RequestParams) {
      return fetchApi<T>("DELETE", `${getANPHost()}${path}`, params);
    },
    async patchCall<T>(path: string, params?: RequestParams) {
      return fetchApi<T>("PATCH", `${getANPHost()}${path}`, params);
    },
};

export default apiANP;