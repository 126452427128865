const Avatar = ({
    src,
    name,
    alt,
    size = 50,
    bgColor = "#ccc",
    textColor = "#fff",
}: {
    src?: string;
    name?: string;
    alt?: string;
    size?: number;
    bgColor?: string;
    textColor?: string;
}) => {
    const avatarStyle = {
        width: size,
        height: size,
        borderRadius: "50%",
        overflow: "hidden",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: bgColor,
        color: textColor,
        fontSize: size / 2.5,
        fontWeight: "bold",
        cursor: "pointer",
    };

    const getInitial = (name?: string) => {
        return name ? name.charAt(0).toUpperCase() : "?";
    };

    return (
        <div style={avatarStyle}>
            {src ? (
                <img
                    src={src}
                    alt={alt || name}
                    style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                    }}
                />
            ) : (
                getInitial(name)
            )}
        </div>
    );
};

export default Avatar;
