import { useFactors } from "../../../hooks/query/useFactors";
// import { useMeanQuantileQuery } from "../../../hooks/useBacktest";
import ReactECharts from "echarts-for-react";
import { FactorStrategy } from "../../../api/schema";
import { toFixed } from "../../../utils/utils";

export const LogReturnsByQuarter = ({ factor }: { factor: FactorStrategy }) => {
    const { useMeanQuantileQuery } = useFactors();
    const meanQuantileQuery = useMeanQuantileQuery(
        factor.userId,
        factor.PK,
        factor.Factor
    );
    //   useEffect(() => {
    //     if (query.data ) {
    //         setSelectedFactor(query.data[3].Factor);
    //     }

    // }, [query.data]);

    if (meanQuantileQuery.data) {
        const options = {
            xAxis: {
                type: "category",
                data: ["Q1 (Low Score)", "Q2", "Q3", "Q4", "Q5 (High Score)"],
            },
            yAxis: {
                type: "value",
                name: "Performance",
                nameLocation: "middle",
                nameGap: 50,
                axisLabel: {
                    formatter: "{value}%",
                    margin: 5,
                },
            },

            series: [
                {
                    data: [
                        meanQuantileQuery.data?.Log_Return_Q1 * 100,
                        meanQuantileQuery.data?.Log_Return_Q2 * 100,
                        meanQuantileQuery.data?.Log_Return_Q3 * 100,
                        meanQuantileQuery.data?.Log_Return_Q4 * 100,
                        meanQuantileQuery.data?.Log_Return_Q5 * 100,
                    ],
                    type: "bar",
                    showBackground: true,
                    backgroundStyle: {
                        color: "rgba(180, 180, 180, 0.2)",
                    },
                },
            ],
            tooltip: {
                trigger: "axis",
                formatter: function (param: any) {
                    return `
                <div><span style=margin-right:10px>${param[0].marker}${param[0].name}</span><span>${toFixed(param[0].value)}%</span></div>
            `;
                },
            },
        };

        return (
            <>
                {/* <FactorSelector factor={selectedFactor} onChange={setSelectedFactor} /> */}
                <h4>Mean Quantile Monthly Returns </h4>
                <ReactECharts option={options} />
            </>
        );
    }

    // if (meanQuantileQuery.error) {
    //     return (
    //         <div>
    //             Error fetching log returns by quarter!
    //         </div>
    //     );
    // }

    return (
        <>
            {/* <FactorSelector factor={selectedFactor} onChange={setSelectedFactor} /> */}
            <div>Loading...</div>
        </>
    );
};
