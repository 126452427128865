import { Col, Row } from "react-bootstrap";
import BacktestSettingsForm from "./ModelSettingsForm";
import { PerformanceDetails } from "./PerformanceDetails";
import { ModelPerformanceChart } from "./ModelPerformanceChart";
import { DrawdownChart } from "./DrawdownChart";
import PortfolioConstituents from "./PortfolioConstituents";
import { RebalanceReturns } from "./RebalanceReturns";
import { useParams } from "react-router-dom";
import { useModels } from "../../../hooks/query/useModel";
import RangeSelector from "./RangeSelector";

export const PortfolioDetail = () => {
    const { modelId = "" } = useParams();
    const { useModelQuery } = useModels();
    const modelQuery = useModelQuery(modelId || "");

    return (
        <Row>
            <div
                style={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "1rem",
                }}
            >
                <div>
                    <h1 className="ms-3">
                        {modelQuery.data ? modelQuery.data.name : ""}
                    </h1>
                </div>
                <div
                    style={{
                        alignSelf: "center",
                    }}
                >
                    <RangeSelector />
                </div>
            </div>
            <Col md={8} sm={12}>
                <div className="px-3">
                    <ModelPerformanceChart />
                    <PortfolioConstituents />
                    <DrawdownChart />
                    <RebalanceReturns />
                    <PerformanceDetails />
                </div>
            </Col>
            <Col md={4}>
                <BacktestSettingsForm />
            </Col>
        </Row>
    );
};
