import { OverlayTrigger, Popover } from "react-bootstrap";
import { BsFillGrid3X3GapFill } from "react-icons/bs";
import { MdAddChart } from "react-icons/md";
import { MdMultilineChart } from "react-icons/md";
import { MdTableChart } from "react-icons/md";
import { BsCashStack } from "react-icons/bs";
import { LuSigma } from "react-icons/lu";
import { MdStackedBarChart } from "react-icons/md";
import { Link } from "react-router-dom";
import { getANPHost } from "../../../utils/envHandler";
import styles from "./Menu.module.scss";

export const Apps = () => {
    const popover = (
        <Popover id="popover-basic" className={styles.popover}>
            <Popover.Header as="h3">Analytical Platform</Popover.Header>
            <Popover.Body>
                <div className={styles.appContainer}>
                    <div className={styles.app}>
                        <Link to={`${getANPHost()}/stockPickingLab`}>
                            <MdAddChart className="app-icon" size="2rem" />
                            <div className="app-name">Stock Picking Lab</div>
                        </Link>
                    </div>
                    <div className={styles.app}>
                        <Link to={`${getANPHost()}/patternLab`}>
                            <MdMultilineChart
                                className="app-icon"
                                size="2rem"
                            />
                            <div className="app-name">Pattern Lab</div>
                        </Link>
                    </div>
                    <div className={styles.app}>
                        <Link
                            to={`${getANPHost()}/optimization-fe/portfolioManager`}
                        >
                            <MdTableChart className="app-icon" size="2rem" />
                            <div className="app-name">Portfolio Manager</div>
                        </Link>
                    </div>
                    <div className={styles.app}>
                        <Link to={`${getANPHost()}/taxes`}>
                            <BsCashStack className="app-icon" size="2rem" />
                            <div className="app-name">Taxes</div>
                        </Link>
                    </div>
                    <div className={styles.app}>
                        <Link to={"/"}>
                            <MdStackedBarChart
                                className="app-icon"
                                size="2rem"
                            />
                            <div className="app-name">Factor Investing</div>
                        </Link>
                    </div>
                    <div className={styles.app}>
                        <Link
                            to={`${getANPHost()}/optimization-fe/marketSentiment`}
                        >
                            <LuSigma className="app-icon" size="2rem" />
                            <div className="app-name">Market Sentiment</div>
                        </Link>
                    </div>
                </div>
            </Popover.Body>
        </Popover>
    );

    return (
        <OverlayTrigger trigger="click" placement="bottom" overlay={popover}>
            <div>
                <span className={`${styles.apps} mx-2`}>
                    <BsFillGrid3X3GapFill size={"2em"} />
                </span>
            </div>
        </OverlayTrigger>
    );
};
