
import { MdStackedLineChart } from "react-icons/md";
import { TbCurrencyDollarOff } from "react-icons/tb";
import { AiFillDollarCircle } from "react-icons/ai";
import styles from './ModelCard.module.scss';
import { useModels } from "../../../hooks/query/useModel";
import { toFixed } from "../../../utils/utils";
import { BacktestChartKeys } from "../../../api/schema";

const ModelPerformance = ({
    userId,
    modelId,
    type
}: {
    userId: string;
    modelId?: string;
    type: BacktestChartKeys;
}) => {
    const {useModelReportQuery} = useModels()
    const modelReportQuery = useModelReportQuery(userId, modelId);

    if (modelReportQuery.data) {
        const report = modelReportQuery.data[type];
        return (
            <div className={styles.row}>
            <div className={styles.metricWrapper}>
                <div className={`${styles.iconBackground}`} style={{
                    backgroundColor: "#D5D1FE"
                }}>
                    <MdStackedLineChart className={styles.icon} color="7266F3"/>
                </div>
                <div className={styles.metric}>
                    <div>Sharpe ratio:</div>
                    {<div>{toFixed(report?.Sharpe || 0)}</div>}
                </div>
            </div>
            <div className={styles.metricWrapper}>
                <div className={`${styles.iconBackground}`} style={{
                    backgroundColor: "#FBDAD1"
                }}>
                    <TbCurrencyDollarOff className={styles.icon} color="F77956"/>
                </div>
                <div className={styles.metric}>
                    <div>Volatility:</div>
                    {<div>{toFixed((report?.Volatility || 0) * 100 )}% p.a.</div>}
                </div>
            </div>
            <div className={styles.metricWrapper}>
                <div className={`${styles.iconBackground}`} style={{
                    backgroundColor: "#F7E6FF"
                }}>
                    <AiFillDollarCircle className={styles.icon} color="AD00FF"/>
                </div>
                <div className={styles.metric}>
                    <div>Return:</div>
                    {<div>{(report?.CAGR || 0) > 0 ? "+" : ""}{toFixed((report?.CAGR || 0) * 100) }% p.a.</div>}
                </div>
            </div>
        </div>
    
        )
    }
  
  return (
    <div>ModelPerformance</div>
  )
}

export default ModelPerformance