import { useRef, useCallback, useEffect } from "react";

function useCachedAsync(asyncFunction: () => Promise<void>, cacheTime = 5000) {
    const cacheRef = useRef<Promise<void> | null>(null);
    const timeoutRef = useRef<NodeJS.Timeout | null>(null);

    const execute = useCallback(async () => {
        // If a cached promise exists and is within the cache time, return it
        console.log("cacheRef.current", cacheRef.current);
        if (cacheRef.current) return cacheRef.current;

        const promise = asyncFunction();

        cacheRef.current = promise;
        console.log("new promise");
        // Set a timeout to clear the cache after the specified time
        timeoutRef.current = setTimeout(() => {
            cacheRef.current = null;
        }, cacheTime);

        return promise;
    }, [asyncFunction, cacheTime]);

    // Clear timeout on unmount to avoid memory leaks
    useEffect(() => {
        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        };
    }, []);

    return { execute };
}

export default useCachedAsync;
