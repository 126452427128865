import { OverlayTrigger, Table, Tooltip } from "react-bootstrap";
import Paginator from "../../common/Pagination";
import { useMemo, useState } from "react";
import { FactorDetailsType, FactorStrategy } from "../../../api/schema";
import { padNumber, toFixed } from "../../../utils/utils";
import { useFactors } from "../../../hooks/query/useFactors";
import Card from "../../common/Card";

const FactorsTable = ({
    factors,
    selectedRow,
    onShownFactorsChange,
    setSelectedRow,
}: {
    factors: FactorStrategy[];
    selectedRow: FactorStrategy | null;
    onShownFactorsChange: (factor: FactorStrategy[]) => void;
    setSelectedRow: (factor: FactorStrategy) => void;
}) => {
    const { useFactorDetailsQuery } = useFactors();
    const factorsDetailsQuery = useFactorDetailsQuery();
    const [page, setPage] = useState(0);
    const [sortField, setSortField] =
        useState<keyof FactorStrategy>("FM_return");
    const [sortDirection, setSortDirection] = useState<"asc" | "desc">("asc");

    const changeSort = (field: keyof FactorStrategy) => {
        setSortField(field);
        const direction =
            sortField === field && sortDirection === "asc" ? "desc" : "asc";
        setSortDirection(direction);
    };
    const pageSize = 20;
    const filteredFactors = useMemo(() => {
        const f = factors
            .sort((a, b) => {
                if (
                    sortField === "T-Stat" ||
                    sortField === "P-Value" ||
                    sortField === "FM_return"
                ) {
                    return sortDirection === "asc"
                        ? (b[sortField] || 0) - (a[sortField] || 0)
                        : (a[sortField] || 0) - (b[sortField] || 0);
                } else {
                    return sortDirection === "asc"
                        ? a.Factor.localeCompare(b.Factor)
                        : b.Factor.localeCompare(a.Factor);
                }
            })
            .slice(page * pageSize, (page + 1) * pageSize);
        onShownFactorsChange(f);
        return f;
    }, [factors, page, onShownFactorsChange, sortField, sortDirection]);
    let factorsDetails =
        (useMemo(() => {
            return factorsDetailsQuery.data?.reduce((acc, factor) => {
                return {
                    ...acc,
                    [factor.Feature]: factor,
                };
            }, {});
        }, [factorsDetailsQuery.data]) as {
            [key: string]: FactorDetailsType;
        }) || {};

    return (
        <Card>
            <Table hover>
                <thead>
                    <tr>
                        <th onClick={() => changeSort("Factor")}>
                            Factor{" "}
                            {sortField === "Factor" &&
                                sortDirection === "asc" &&
                                "▲"}{" "}
                            {sortField === "Factor" &&
                                sortDirection === "desc" &&
                                "▼"}{" "}
                        </th>
                        <th>Factor Group</th>
                        <th onClick={() => changeSort("P-Value")}>
                            Statistical Confidence{" "}
                            {sortField === "P-Value" &&
                                sortDirection === "asc" &&
                                "▲"}{" "}
                            {sortField === "P-Value" &&
                                sortDirection === "desc" &&
                                "▼"}
                        </th>
                        <th onClick={() => changeSort("T-Stat")}>
                            Signal Strength{" "}
                            {sortField === "T-Stat" &&
                                sortDirection === "asc" &&
                                "▲"}{" "}
                            {sortField === "T-Stat" &&
                                sortDirection === "desc" &&
                                "▼"}
                        </th>
                        <th onClick={() => changeSort("FM_return")}>
                            Factor Mimicking Return{" "}
                            {sortField === "FM_return" &&
                                sortDirection === "asc" &&
                                "▲"}{" "}
                            {sortField === "FM_return" &&
                                sortDirection === "desc" &&
                                "▼"}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {filteredFactors.map((row, index) => (
                        <tr
                            key={index}
                            onClick={() => setSelectedRow(row)}
                            className={
                                selectedRow?.Factor === row.Factor
                                    ? "active-row"
                                    : ""
                            }
                        >
                            <td>{row.Factor}</td>
                            <td>{factorsDetails[row.Factor]?.Bucket}</td>
                            <td>
                                <OverlayTrigger
                                    placement="top"
                                    overlay={
                                        <Tooltip>
                                            {padNumber(
                                                toFixed(
                                                    row["P-Value"] * 100,
                                                    4
                                                ),
                                                4
                                            )}
                                            %
                                        </Tooltip>
                                    }
                                >
                                    <span>
                                        {padNumber(
                                            toFixed(row["P-Value"] * 100, 4),
                                            4
                                        )}
                                        %
                                    </span>
                                </OverlayTrigger>
                            </td>
                            <td>
                                <OverlayTrigger
                                    placement="top"
                                    overlay={
                                        <Tooltip>
                                            {padNumber(
                                                toFixed(row["T-Stat"], 4),
                                                4
                                            )}
                                        </Tooltip>
                                    }
                                >
                                    <span>
                                        {padNumber(
                                            toFixed(row["T-Stat"], 4),
                                            4
                                        )}
                                    </span>
                                </OverlayTrigger>
                            </td>
                            <td>
                                <OverlayTrigger
                                    placement="top"
                                    overlay={
                                        <Tooltip>
                                            {padNumber(
                                                toFixed(
                                                    (row.FM_return || 0) * 100,
                                                    2
                                                ),
                                                2
                                            )}
                                            %
                                        </Tooltip>
                                    }
                                >
                                    <span>
                                        {padNumber(
                                            toFixed(
                                                (row.FM_return || 0) * 100,
                                                2
                                            ),
                                            2
                                        )}
                                        %
                                    </span>
                                </OverlayTrigger>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            <Paginator
                pageSize={pageSize}
                items={factors.length}
                activePage={page}
                onPageChange={setPage}
            />
        </Card>
    );
};

export default FactorsTable;
