import React, { useRef } from "react";
import {
    CloseButton,
    Col,
    Form,
    InputGroup,
    Overlay,
    Popover,
    Row,
} from "react-bootstrap";
import { BsCalendar2WeekFill } from "react-icons/bs";
import Calendar from "react-calendar";
import useDateRange from "./useDateRange";

type DateRangeInputProps = {
    dateRange: [Date, Date] | undefined;
    onDateRangeChange: (newDateRange: [Date, Date] | undefined) => void;
};

const DateRangeInput: React.FC<DateRangeInputProps> = ({
    dateRange,
    onDateRangeChange,
}) => {
    const target = useRef(null);
    const {
        showCalendar,
        setShowCalendar,
        formattedDateRange,
        handleInputChange,
        handleInputBlur,
        handleCalendarSelect,
    } = useDateRange(dateRange, onDateRangeChange);

    return (
        <>
            <InputGroup>
                <Form.Control
                    type="text"
                    value={formattedDateRange}
                    onBlur={handleInputBlur}
                    onChange={handleInputChange}
                    placeholder="MM/YYYY-MM/YYYY"
                />
                <InputGroup.Text>
                    <div
                        ref={target}
                        onClick={() => setShowCalendar(!showCalendar)}
                        style={{
                            cursor: "pointer",
                        }}
                    >
                        <BsCalendar2WeekFill />
                    </div>
                </InputGroup.Text>
            </InputGroup>

            <Overlay
                target={target.current}
                show={showCalendar}
                placement="left"
            >
                {(props) => (
                    <Popover id="popover-basic" {...props}>
                        <Popover.Header>
                            <Row>
                                <Col sm={10}>Select Date Range</Col>
                                <Col sm={2}>
                                    <CloseButton
                                        onClick={() => setShowCalendar(false)}
                                    />
                                </Col>
                            </Row>
                        </Popover.Header>
                        <Popover.Body>
                            <Calendar
                                maxDetail="year"
                                maxDate={new Date()}
                                selectRange={true}
                                onChange={handleCalendarSelect}
                                value={dateRange}
                            />
                        </Popover.Body>
                    </Popover>
                )}
            </Overlay>
        </>
    );
};

export default DateRangeInput;
