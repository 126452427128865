import {
    createContext,
    useCallback,
    useContext,
    useMemo,
    useState,
} from "react";

const BusyContext = createContext({
    busy: false,
    setIsBusy: (isBusy: boolean) => {},
});

export const BusyProvider = ({ children }: { children: React.ReactNode }) => {
    const [busy, setBusy] = useState(false);

    const setIsBusy = useCallback((isBusy: boolean) => {
        setBusy(isBusy);
    }, []);

    const value = useMemo(
        () => ({
            busy,
            setIsBusy,
        }),
        [busy, setIsBusy]
    );

    return (
        <BusyContext.Provider value={value}>{children}</BusyContext.Provider>
    );
};

export const useBusy = () => {
    const context = useContext(BusyContext);
    if (!context) {
        throw new Error("useLoading must be used within LoadingProvider");
    }
    return context;
};
