import { useEffect, useMemo, useState } from "react";
import { useFactors } from "../../../../../hooks/query/useFactors";
import { FactorStrategy } from "../../../../../api/schema";
import { useFactorApi } from "../../../../../hooks/api/aws/useFactorApi";

const useFactorMimickingTable = (filterBarFilters: any) => {
    const { zoomFactorMimickingData } = useFactorApi();
    const { useFactorDetailsQuery, useZoomedFactorsListQuery } = useFactors();
    const [usedUniverse, setUsedUniverse] = useState("sp100");
    const [usedDateRange, setUsedDateRange] = useState<
        [Date, Date] | undefined
    >();
    const [isDirty, setIsDirty] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [strategyId, setStrategyId] = useState<string>("admin_sp100_M");
    const factorsDetailsQuery = useFactorDetailsQuery();
    const factorListQuery = useZoomedFactorsListQuery(strategyId);

    useEffect(() => {
        if (
            filterBarFilters.zoomDateRange &&
            filterBarFilters.zoomDateRange !== usedDateRange
        ) {
            setIsDirty(true);
        }
        if (filterBarFilters.universe !== usedUniverse) {
            setIsDirty(true);
        }
    }, [filterBarFilters, usedDateRange, usedUniverse, isDirty]);

    const applyFilter = (
        value: number,
        filterValue: number,
        filterOperator: "<" | ">" | "="
    ) => {
        if (filterOperator === "<") {
            return value < filterValue;
        } else if (filterOperator === ">") {
            return value > filterValue;
        } else {
            return value === filterValue;
        }
    };

    const onGoClick = async (e?: any, setToDefault?: boolean) => {
        const formatDate = (d: Date) =>
            `${d.getFullYear()}-${(d.getMonth() + 1).toString().padStart(2, "0")}-01`;
        if (e) {
            e.preventDefault();
        }
        const strategyIdMap = {
            sp100: "admin_sp100_M",
            sp500: "admin_sp500_M",
        } as {
            [key: string]: string;
        };
        const rootStrategyId = strategyIdMap[filterBarFilters.universe];
        let newStrategyId = rootStrategyId;

        if (
            filterBarFilters.zoomDateRange &&
            (!setToDefault ||
                (typeof setToDefault === "boolean" && !setToDefault))
        ) {
            setIsLoading(true);
            const response = await zoomFactorMimickingData({
                strategyId: rootStrategyId,
                userId: "admin",
                from: formatDate(filterBarFilters.zoomDateRange[0]),
                to: formatDate(filterBarFilters.zoomDateRange[1]),
                frequency: "M",
                pool: filterBarFilters.universe,
            });
            setIsLoading(false);
            setUsedDateRange(filterBarFilters.zoomDateRange);
            newStrategyId = response.strategyId;
        }

        setStrategyId(newStrategyId);
        setUsedUniverse(filterBarFilters.universe);
        setIsDirty(false);
    };

    const factorBuckets = useMemo(() => {
        const map = new Map<string, string>();
        return (factorsDetailsQuery.data || []).reduce((acc, val) => {
            map.set(val.Feature, val.Bucket);
            return map;
        }, new Map<string, string>());
    }, [factorsDetailsQuery.data]);

    const factorList = useMemo(() => {
        return factorListQuery.data
            ? factorListQuery.data.reduce((acc: any, factor) => {
                  return [...acc, factor];
              }, [])
            : [];
    }, [factorListQuery.data]);

    const filteredFactors = useMemo(() => {
        return factorList
            .filter(
                (f: FactorStrategy) =>
                    !filterBarFilters.search ||
                    f.Factor.toLowerCase().includes(
                        filterBarFilters.search.toLowerCase()
                    )
            )
            .filter(
                (f: FactorStrategy) =>
                    !filterBarFilters.selectedBucket ||
                    factorBuckets?.get(f.Factor) ===
                        filterBarFilters.selectedBucket
            )
            .filter((f: FactorStrategy) =>
                applyFilter(
                    f["P-Value"],
                    (filterBarFilters.pValue &&
                        filterBarFilters.pValue / 100) ||
                        0,
                    filterBarFilters.pValueOperator || "="
                )
            )
            .filter((f: FactorStrategy) =>
                applyFilter(
                    f["T-Stat"],
                    filterBarFilters.tStat || 0,
                    filterBarFilters.tStatOperator || "="
                )
            )
            .filter((f: FactorStrategy) => {
                if (filterBarFilters.signalDirection === "") {
                    return true;
                }

                return filterBarFilters.signalDirection === "positive"
                    ? f.Direction > 0
                    : f.Direction < 0;
            });
    }, [factorList, factorBuckets, filterBarFilters]);

    const resetTable = () => {
        setStrategyId("admin_sp100_M");
        setUsedUniverse("sp100");
        setUsedDateRange(undefined);
        setIsDirty(false);
    };

    return {
        isDirty,
        isLoading,
        strategyId,
        filteredFactors,
        onGoClick,
        resetTable,
    };
};

export default useFactorMimickingTable;
