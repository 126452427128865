import './Timeline.css';
import moment from 'moment';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { toFixed } from '../../../utils/utils';
import { FaSave } from "react-icons/fa";


const Timeline = ({
    dates,
    performance,
    selectedDate,
    lastUpdate,
    onDateChange,
    onDownloadPress
}: {
    dates: string[];
    performance: (number | null)[];
    selectedDate: string;
    lastUpdate?: string;
    onDateChange: (date: string) => void;
    onDownloadPress: (date: string) => void;
}) => {
  return (
    <section className="time-line-box">
    <div className="swiper-container text-center" tabIndex={0}> 
        <div className="swiper-wrapper">
            {
                
                dates.map((date, index) => {
                    const isSelected = selectedDate === date;
                    const className = isSelected ? "status active" : "status"; 
                    const isPositive = (performance[index] || 0) > 0;
                    const gain =  Math.abs(toFixed((performance[index] || 0) * 100));
                    const isCurrent = index === dates.length - 1;
                    const arrow = isPositive ? <span style={{
                        color: "green",
                        marginRight: "5px"
                    }}>▲</span> : <span style={{
                        color: "red",
                        marginRight: "5px"
                    }}>▼</span>;
                    const percentage = <div className="timestamp"><span>{gain}%{arrow}</span></div>

                    return isCurrent ? (<OverlayTrigger key={date} placement="top" delay={{ show: 100, hide: 500 }} overlay={(props) => <Tooltip id="button-tooltip" {...props}>Updated at: {date} {lastUpdate}</Tooltip>}>
                            <div key={date} className="swiper-slide" onClick={() => onDateChange(date)} tabIndex={0}>
                                <div className="timestamp"><span className="date"><FaSave className='ms-1' onClick={() => onDownloadPress(date)}/> {moment(date).format("MMM,YYYY")}</span> </div>
                                {percentage}
                                <div className={className}><span>Current Rebalance</span></div>
                            </div>
                        </OverlayTrigger>) : (
                        <div key={date} className="swiper-slide" onClick={() => onDateChange(date)} tabIndex={0}>
                            <div className="timestamp"><span className="date"><FaSave className='ms-1' onClick={() => onDownloadPress(date)} /> {moment(date).format("MMM,YYYY")}</span></div>
                            {percentage}
                            <div className={className}><span>Rebalance</span></div>
                        </div>
                    );
                })
            }
        </div>
        <div className="swiper-pagination"></div>
    </div>
</section>
  )
}


export default Timeline;