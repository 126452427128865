import { useParams } from "react-router-dom";
import { DrawdownType, DrawdownTypes } from "../../../api/schema";
import ReactECharts from "echarts-for-react";
import { useModels } from "../../../hooks/query/useModel";
import moment from "moment";
import { Placeholder } from "react-bootstrap";
import { padNumber, toFixed } from "../../../utils/utils";

export const DrawdownChart = () => {
    let { modelId = "" } = useParams();
    const { useModelQuery, useModelDrawdownQuery } = useModels();
    const modelQuery = useModelQuery(modelId);
    const model = modelQuery.data;
    const drawdownQuery = useModelDrawdownQuery(model?.userId, model?.modelId);

    if (
        drawdownQuery.data &&
        modelQuery.data &&
        Object.keys(drawdownQuery.data).length > 0
    ) {
        const drawdownData = drawdownQuery.data as DrawdownType;
        const backtestPeriod = moment(modelQuery.data.backtest?.backtestPeriod);
        const type = "Strategy" as DrawdownTypes;
        const dates = Object.keys(drawdownData[type]).filter((date) =>
            moment(date).isAfter(backtestPeriod)
        );
        const options = {
            grid: {
                left: "3%",
                right: "4%",
                bottom: "3%",
                containLabel: true,
            },
            xAxis: {
                type: "time",
            },
            yAxis: {
                type: "value",
                name: "Drawdown",
                nameLocation: "middle",
                nameGap: 50,
                axisLabel: {
                    formatter: "{value} %",
                    margin: 5,
                },
            },
            series: [
                {
                    name: type,
                    type: "line",
                    data: dates.map((date: any) => [
                        new Date(date),
                        drawdownData[type][date] * 100,
                    ]),
                    showSymbol: false,
                },
            ],
            tooltip: {
                trigger: "axis",
                formatter: function (params: any) {
                    const date = new Date(params[0].value[0]);
                    const value = params[0].value[1];
                    return `
                          <div>Date: ${date.toLocaleDateString()}</div>
                          <div style="display:flex; justify-content:space-between"><span style="margin-right:10px">${params[0].marker} Drawdown</span> <span>${padNumber(toFixed(value, 2), 2)}%</span></div>
                        `;
                },
            },
        };

        return (
            <div className="my-3">
                <h3>Drawdowns</h3>
                <ReactECharts option={options} />
            </div>
        );
    }

    if (drawdownQuery.error) {
        return <div>Error fetching performance metrics!</div>;
    }

    return (
        <div className="my-3">
            <h3>Drawdowns</h3>
            <Placeholder as="div" animation="glow">
                <Placeholder
                    xs={12}
                    style={{
                        height: "300px",
                    }}
                />
            </Placeholder>
        </div>
    );
};
