import { BacktestChartKeys, FactorModelType } from "../../../api/schema";
import ReactECharts from "echarts-for-react";
import styles from "./ModelCard.module.scss";
import Card from "../../common/Card";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useModels } from "../../../hooks/query/useModel";
import ModelPerformance from "../../common/ModelPerformance";
import { toFixed } from "../../../utils/utils";
import PortfolioTitle from "./PortfolioTitle";

// const getOptions = () => {
//     const options = {
//       xAxis: {
//         type: 'time',
//         axisLabel: {
//           formatter: (function(value: number){
//             const date = new Date(value);
//             return [date.getFullYear(), date.getMonth() + 1, date.getDate()].join('-');
//           })
//         }
//       },
//       yAxis: {
//         type: 'value',
//         name: 'Portfolio Value'
//       },
//       test_id: 'test',
//       series: [{
//           data: [[new Date("2024-03-12"), 1], [new Date("2024-03-13"), 2], [new Date("2024-03-14"), 1], [new Date("2024-03-15"), 3]],
//           type: 'line',
//           name: "Test",
//       }],
//       tooltip: {
//         trigger: 'axis',
//       },
//     };
//     return options;
// };

const ModelCard = ({ model }: { model: FactorModelType }) => {
    const navigate = useNavigate();
    const { useModelReportQuery, useModelChartQuery } = useModels();
    const modelReportQuery = useModelReportQuery(model.userId, model.modelId);
    const query = useModelChartQuery(model.userId, model.modelId);

    if (model.factor && Object.keys(model.factor).length > 0) {
        const timeseries = query.data || [];
        const type = "Strategy" as BacktestChartKeys;
        const currentValue = timeseries.slice(-1)[0] || {
            date: new Date(),
            [type]: 0,
        };

        const options = {
            xAxis: {
                type: "time",
            },
            yAxis: {
                type: "value",
                min: function (value: any) {
                    return Math.floor(value.min);
                },
                max: function (value: any) {
                    return Math.round(value.max);
                },
                axisLabel: {
                    // eslint-disable-next-line no-template-curly-in-string
                    formatter: "${value}",
                    margin: 5,
                },
                name: "Final Equity",
                nameLocation: "middle",
                nameGap: 80,
            },
            series: {
                data: timeseries.map((point: any) => [
                    new Date(point.date),
                    point[type],
                ]),
                type: "line",
                name: type,
                showSymbol: false,
                markPoint: {
                    data: [
                        {
                            symbol: "pin",
                            symbolSize: 100,
                            value: toFixed(currentValue[type] || 0, 0),
                            coord: [currentValue.date, currentValue[type]],
                            label: {
                                formatter: function (params: any) {
                                    return `$ ${toFixed(params.value, 0)}`;
                                },
                            },
                        },
                    ],
                },
            },
            tooltip: {
                trigger: "axis",
                formatter: function (params: any) {
                    const date = new Date(params[0].value[0]);
                    const value = params[0].value[1];
                    return `${date.toLocaleDateString()}<br/>$ ${toFixed(value, 0)}`;
                },
            },
        };

        return (
            <div
                className={styles.card}
                style={{
                    height: "100%",
                }}
            >
                <PortfolioTitle model={model} />
                <div onClick={() => navigate(`/${model.modelId}`)}>
                    <Card
                        style={{
                            height: "inherit",
                            display: "flex",
                            flexDirection: "column",
                            cursor: "pointer",
                        }}
                    >
                        <ReactECharts option={options} />
                        {modelReportQuery.data && (
                            <div className={styles.row}>
                                <div className="mt-3">
                                    <ModelPerformance
                                        userId={model.userId}
                                        modelId={model.modelId}
                                        type={type}
                                    />
                                </div>
                            </div>
                        )}
                    </Card>
                </div>
            </div>
        );
    }

    return (
        <div
            className={styles.card}
            style={{
                height: "100%",
                marginBottom: "30px",
            }}
        >
            {/* <h4>{model.name || "In process..."}</h4> */}
            <>
                <PortfolioTitle model={model} />
            </>
            <Card
                style={{
                    height: "inherit",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Button onClick={() => navigate(`/model/${model.modelId}`)}>
                    Setup your portfolio
                </Button>
            </Card>
        </div>
    );
};

export default ModelCard;
