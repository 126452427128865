import { useAuth } from "../../../hooks/useAuth";
import { Menu as ReactMenu, MenuItem } from "@szhsin/react-menu";
import Avatar from "./Avatar";

export const User = () => {
    const { user, logout } = useAuth();
    if (!user?.email) return null;

    return (
        <div className="mx-2">
            <ReactMenu
                menuButton={
                    <div>
                        <Avatar
                            name={user?.email}
                            size={40}
                            bgColor="#007BFF"
                            textColor="#fff"
                        />
                    </div>
                }
            >
                <MenuItem disabled>{user?.email}</MenuItem>
                <MenuItem disabled>Settings</MenuItem>
                <MenuItem onClick={() => logout()}>Logout</MenuItem>
            </ReactMenu>
        </div>
    );
};
