import { createBrowserRouter } from "react-router-dom";
import Root from "../controls/layout/Root";
import Menu from "../controls/layout/menu";
import FullScreenContent from "../controls/layout/FullScreenContent";
import { PortfolioDetail } from "../controls/pages/portfolioDetail";
import ProtectedRoute from "../controls/common/ProtectedRoute";

import ModelConfiguration from "../controls/pages/modelConfiguration";
import Dashboard from "../controls/pages/dashboard";
import Strategy from "../controls/pages/strategy";
import { BusyProvider } from "../hooks/useBusy";
import { MessageProvider } from "../hooks/useMessages";
import { AuthProvider } from "../context/authContext";
import QueryProvider from "../context/queryContext";

const router = createBrowserRouter([
    {
        path: "/",
        element: (
            <QueryProvider>
                <AuthProvider>
                    <ProtectedRoute>
                        <MessageProvider>
                            <BusyProvider>
                                <Root>
                                    <Menu />
                                    <FullScreenContent />
                                </Root>
                            </BusyProvider>
                        </MessageProvider>
                    </ProtectedRoute>
                </AuthProvider>
            </QueryProvider>
        ),
        children: [
            {
                path: "",
                element: <Dashboard />,
            },
            {
                path: ":modelId",
                element: <PortfolioDetail />,
            },
            {
                path: "model",
                element: <ModelConfiguration />,
            },
            {
                path: "model/:modelId",
                element: <ModelConfiguration />,
            },
            {
                path: "strategy/:modelId",
                element: <Strategy />,
            },
        ],
    },
]);

export default router;
