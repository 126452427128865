import { useParams } from "react-router-dom";
import { useModels } from "../../../hooks/query/useModel";
import { useFactors } from "../../../hooks/query/useFactors";
import ModelCharacteristics from "./ModelCharacteristics";
import Card from "../../common/Card";
import { Col, Row } from "react-bootstrap";
import FactorInfo from "../../common/FactorInfo";
import { LogReturnsByQuarter } from "./LogReturnsByQuarter";
import { useEffect, useState } from "react";
import SettingsForm, { SettingsFormProps } from "./SettingsForm";
import RankingTable from "./RankingTable";
import Distribution from "./Distribution";
import { PortfolioConstructionType } from "../../../api/schema";
import { getUniverse, isLowestScore } from "../../../utils/utils";
import { useUniverse } from "../../../hooks/query/useUniverse";

export type PortfolioType = "long-short" | "long" | "short";

const Strategy = () => {
    let { modelId = "" } = useParams();
    const { useModelQuery } = useModels();
    const { useFactorsListQuery } = useFactors();
    const { useUniverseQuery } = useUniverse();
    const modelQuery = useModelQuery(modelId);
    const universeQuery = useUniverseQuery(
        getUniverse(modelQuery.data?.strategyId) || ""
    );
    const factorsQuery = useFactorsListQuery();
    const [slider, setSlider] = useState(4);
    const [portfolioType, setPortfolioType] =
        useState<PortfolioConstructionType>("LongShort");
    const [rankingRules, setRankingRules] = useState<"highest" | "lowest">(
        "lowest"
    ); // ["highest", "lowest]
    const universeCount = universeQuery.data?.assetsCount || 0;

    useEffect(() => {
        if (modelQuery.data) {
            const backtest = modelQuery.data?.backtest || {
                portfolioConstruction: "LongShort",
                numberOfStocks: 4,
                ascending: true,
            };
            setSlider(backtest.numberOfStocks);
            setPortfolioType(backtest.portfolioConstruction);
            setRankingRules(
                isLowestScore(
                    backtest.ascending,
                    backtest.portfolioConstruction
                )
                    ? "lowest"
                    : "highest"
            );
        }
    }, [modelQuery.data]);

    const onSettingsChange = (settings: SettingsFormProps) => {
        if (settings.prop === "slider") {
            setSlider(settings.value as number);
        }

        if (settings.prop === "portfolioType") {
            setPortfolioType(settings.value as PortfolioConstructionType);
        }

        if (settings.prop === "rankingRules") {
            setRankingRules(settings.value as "highest" | "lowest");
        }
    };

    if (modelQuery.data && factorsQuery.data) {
        const factorName = Object.keys(modelQuery.data.factor || {})[0];
        const strategy = factorsQuery.data.find(
            (strategy) => strategy.PK === modelQuery.data.strategyId
        );
        const factorData = strategy?.factors.find(
            (f) => f.Factor === factorName
        );

        return (
            <>
                <h1>Strategy: {modelQuery.data.name}</h1>
                <Row className="mb-4">
                    <Col md={4} className="mt-3">
                        <Card
                            style={{
                                padding: "1rem",
                            }}
                        >
                            <SettingsForm
                                onSettingsChange={onSettingsChange}
                                model={modelQuery.data}
                            />
                        </Card>
                    </Col>
                    <Col md={8} className="mt-3">
                        <Card
                            style={{
                                minHeight: "100%",
                            }}
                        >
                            <Row>
                                <Col md={6}>
                                    <h3>Target portfolio composition</h3>
                                    <Distribution
                                        portfolioType={portfolioType}
                                        slider={slider}
                                    />
                                </Col>
                                <Col md={6}>
                                    <h3>Assets ranked by model</h3>
                                    <RankingTable
                                        portfolioType={portfolioType}
                                        slider={slider}
                                        assetCount={universeCount}
                                        rankingRule={rankingRules}
                                    />
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <h3>Base factor model characteristics</h3>
                    <Col lg={8} className="mt-3">
                        <Card>
                            {factorsQuery.data && (
                                <>
                                    <h4>
                                        Visualisation of strategy performance
                                    </h4>
                                    <ModelCharacteristics
                                        userId={modelQuery.data.userId}
                                        strategyId={modelQuery.data.strategyId}
                                        factor={
                                            Object.keys(
                                                modelQuery.data.factor || {}
                                            )[0]
                                        }
                                        rankingRule={rankingRules}
                                        portfolioType={portfolioType}
                                        direction={
                                            factorData?.Direction &&
                                            factorData.Direction > 0
                                                ? 1
                                                : -1
                                        }
                                    />
                                </>
                            )}
                        </Card>
                    </Col>
                    <Col lg={4} className="mt-3">
                        <Card>
                            <h3>
                                Average Performance of Low score and High score{" "}
                            </h3>
                            <LogReturnsByQuarter
                                userId={modelQuery.data.userId}
                                strategyId={modelQuery.data.strategyId}
                                factor={
                                    Object.keys(modelQuery.data.factor || {})[0]
                                }
                            />
                        </Card>
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col>
                        <Card
                            style={{
                                marginBottom: "1rem",
                            }}
                        >
                            <FactorInfo modelId={modelId} />
                        </Card>
                    </Col>
                </Row>
            </>
        );
    }

    return (
        <>
            <div>Loading...</div>
        </>
    );
};

export default Strategy;
