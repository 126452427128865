import { useEffect, useRef } from "react";

// creating the custom useInterval hook
export function useInterval(callback: () => any, delay: number) {
    const savedCallback = useRef<any>();

    // To remember the latest callback .
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    useEffect(() => {
        function func() {
            savedCallback.current();
        }
        if (delay !== null) {
            let id = setInterval(func, delay);
            return () => clearInterval(id);
        }
    }, [delay]);
}
