import { useQuery } from "@tanstack/react-query";
import {
    BacktestChartItem,
    BacktestReportResponse,
    DrawdownResponseType,
    FactorModelType,
    RebalanceReturnsType,
} from "../../api/schema";
import { useModelApi } from "../api/aws/useModelApi";

export const useModels = () => {
    const {
        fetchAllPortfolios,
        fetchModelReport,
        fetchModelTimeseries,
        fetchModelDrawdown,
        fetchModelReturns,
        fetchModelCurrentReturns,
        fetchScore,
    } = useModelApi();

    const useModelsQuery = <TData = FactorModelType[]>(
        select?: (strategies: FactorModelType[]) => TData
    ) =>
        useQuery<FactorModelType[], Error, TData>({
            queryKey: ["models"],
            queryFn: () => fetchAllPortfolios(),
            select,
        });

    const useModelQuery = (modelId: string) =>
        useModelsQuery<FactorModelType>(
            (models) =>
                models.find(
                    (model) => model.modelId === modelId
                ) as FactorModelType
        );

    const useModelReportQuery = <TData = BacktestReportResponse>(
        userId?: string,
        modelId?: string,
        select?: (strategies: BacktestReportResponse) => TData
    ) =>
        useQuery<BacktestReportResponse, Error, TData>({
            queryKey: ["model", "table", modelId],
            queryFn: () => fetchModelReport(userId || "", modelId || ""),
            select,
            enabled: !!userId && !!modelId,
        });

    const useModelChartQuery = <TData = BacktestChartItem[]>(
        userId?: string,
        modelId?: string,
        select?: (strategies: BacktestChartItem[]) => TData
    ) =>
        useQuery<BacktestChartItem[], Error, TData>({
            queryKey: ["model", "chart", modelId],
            queryFn: () => fetchModelTimeseries(userId || "", modelId || ""),
            select,
            enabled: !!userId && !!modelId,
        });

    const useModelDrawdownQuery = <TData = DrawdownResponseType>(
        userId?: string,
        modelId?: string,
        select?: (strategies: DrawdownResponseType) => TData
    ) =>
        useQuery<DrawdownResponseType, Error, TData>({
            queryKey: ["model", "drawdown", modelId],
            queryFn: () => fetchModelDrawdown(userId || "", modelId || ""),
            select,
            enabled: !!userId && !!modelId,
        });

    const useModelReturnsQuery = <TData = RebalanceReturnsType>(
        userId?: string,
        modelId?: string,
        select?: (strategies: RebalanceReturnsType) => TData
    ) =>
        useQuery<RebalanceReturnsType, Error, TData>({
            queryKey: ["model", "returns", modelId],
            queryFn: () => fetchModelReturns(userId || "", modelId || ""),
            select,
            enabled: !!userId && !!modelId,
        });

    const useModelCurrentReturnsQuery = <TData = RebalanceReturnsType>(
        userId?: string,
        modelId?: string,
        select?: (strategies: RebalanceReturnsType) => TData
    ) =>
        useQuery<RebalanceReturnsType, Error, TData>({
            queryKey: ["model", "current-returns", modelId],
            queryFn: () =>
                fetchModelCurrentReturns(userId || "", modelId || ""),
            select,
            enabled: !!userId && !!modelId,
            refetchInterval: 60000,
        });

    const useDownloadScore = <TData = any>(
        userId?: string,
        modelId?: string,
        date?: string
    ) =>
        useQuery<any, Error, TData>({
            queryKey: ["model", "score", modelId, date],
            queryFn: () => fetchScore(userId || "", modelId || "", date || ""),
            enabled: !!userId && !!modelId && !!date,
        });

    return {
        useModelsQuery,
        useModelQuery,
        useModelReportQuery,
        useModelChartQuery,
        useModelDrawdownQuery,
        useModelReturnsQuery,
        useModelCurrentReturnsQuery,
        useDownloadScore,
    };
};
