import { useQuery } from "@tanstack/react-query";
import { BenchmarkResponseType, UniverseType } from "../../api/schema";
import { useUniverseApi } from "../api/aws/useUniverseApi";

export const useUniverse = () => {
    const { fetchUniverse, fetchUniverses, fetchBenchmarks } = useUniverseApi();

    const useUniverseQuery = <TData = UniverseType>(universeName: string) =>
        useQuery<UniverseType, Error, TData>({
            queryKey: ["universe", universeName],
            queryFn: () => fetchUniverse(universeName),
            enabled: !!universeName,
        });

    const useUniversesQuery = <TData = UniverseType[]>() =>
        useQuery<UniverseType[], Error, TData>({
            queryKey: ["universe"],
            queryFn: () => fetchUniverses(),
        });

    const useBenchmarksQuery = <TData = BenchmarkResponseType>() =>
        useQuery<BenchmarkResponseType, Error, TData>({
            queryKey: ["benchmarks"],
            queryFn: () => fetchBenchmarks(),
        });

    return {
        useUniverseQuery,
        useUniversesQuery,
        useBenchmarksQuery,
    };
};
